<footer class="d-flex flex-column align-self-end bg-charcoal">
    <div class="container-fluid mt-5 d-none d-md-block">
        <div class="row mb-3 desktop-footer" >
            <div class="col-12 col-md-4 align-self-center">
                <div class="row d-none d-md-flex flex-column">
                    <div class="col text-center d-flex align-items-center justify-content-end">
                        
                        <div class="download_app_section mr-4">
                            <p class="h2 lemon-milk-regular text-accent">Download the app</p>
                            <p class="h3 text-light montserrat-regular">Book your activities</p>
                        </div>
                    </div>
                    <div class="text-center d-flex justify-content-center">
                        
                        <app-app-store-logos  [classes]="['d-flex', 'flex-column']" [ios_app_link]="ios_app_link" [android_app_link]="android_app_link"></app-app-store-logos>
                    </div>
                </div>
            </div>
            <div class="col-md-1 border-right" style="border-right-width: 5px !important;"></div>
            <div class="col-12 col-md-6 d-flex text-light flex-column justify-content-between">
                <div class="row justify-content-between">
                    <div class="col-md-2 "></div>
                    <div class="col-12 col-md-5 text-center text-md-left">
                        <h4>Services</h4>
                        <ul class="list-group footer-list-group">
                            <ng-container *ngFor="let category of categories">
                                <li class="list-group-item border-0 p-0">
                                    <a [routerLink]="'/categories/' + category.slug" routerLinkActive="active" (click)="setActiveCategory(category)">{{ category.name }}</a>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                    <div class="col-12 col-md-5 text-center text-md-left mt-3 mt-md-0">
                        <h4>Links</h4>
                        <ul class="list-group footer-list-group">
                            
                            <li *ngIf="has_about_us_page === true" class="list-group-item border-0 p-0">
                                <a routerLink="/legal-collection/about-us">About Us</a>
                            </li>
                            <li  *ngIf="has_terms_and_conditions_page === true" class="list-group-item border-0 p-0">
                                <a routerLink="/legal-collection/terms_and_conditions">Terms & Conditions</a>
                            </li>
                            <li *ngIf="has_gallery_page === true" class="list-group-item border-0 p-0">
                                <a routerLink="/gallery">Gallery</a>
                            </li>
                           
                            <li  *ngIf="has_privacy_policy_page === true" class="list-group-item border-0 p-0">
                                <a routerLink="/legal-collection/privacy-policy">Privacy Policy</a>
                            </li>
                           
                            <li class="list-group-item border-0 p-0">
                                <a routerLink="/contact-us/booking_option">Block Booking Enquires</a>
                            </li>
                            
                        </ul>
                    </div>
                </div>
                <div class="row mt-3 text-light">
                    
                    <div class="col-12 col-md-12 d-flex justify-content-around justify-content-between">
                        <a *ngIf="facebook_url" [href]="facebook_url" class="btn btn-dark">
                            <i class="fab fa-facebook-square mdi-24px mr-0"></i>
                        </a>
                        <a *ngIf="youtube_url" [href]="youtube_url" class="btn btn-dark">
                            <i class="fab fa-youtube mdi-24px mr-0"></i>
                        </a>
                        <a *ngIf="instagram_url" [href]="instagram_url" class="btn btn-dark">
                            <i class="fab fa-instagram mdi-24px mr-0"></i>
                        </a>
                        <a *ngIf="twitter_url" [href]="twitter_url" class="btn btn-dark">
                            <i class="fab fa-twitter mdi-24px mr-0"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>



        <div class="row mb-3 ipad-footer">
            <div class="col-12 col-md-12 align-self-center" *ngIf="currentPlatform == 'web'">
                <div class="row d-none d-md-flex flex-column">
                    <div class="text-center">
                     
                        <div class="download_app_section mr-4">
                            <p class="h2 lemon-milk-regular text-accent">Download the app</p>
                            <p class="h3 montserrat-regular text-light">Book your activities</p>
                        </div>
                    
                        
                    </div>
                    <div class="text-center d-flex justify-content-center">
                        <app-app-store-logos  [classes]="['d-flex', 'flex-column']" [ios_app_link]="ios_app_link" [android_app_link]="android_app_link"></app-app-store-logos>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12 mt-5"></div>
            <div class="col-12 col-md-12 m-5 text-light d-flex flex-column justify-content-between">
                <div class="row">
                    <div class="col-12 col-md-6 text-center text-md-left">
                        <h4>Services</h4>
                        <ul class="list-group footer-list-group">
                            <ng-container *ngFor="let category of categories">
                                <li class="list-group-item border-0 p-0">
                                    <a [routerLink]="'/categories/' + category.slug" routerLinkActive="active" (click)="setActiveCategory(category)">{{ category.name }}</a>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                    <div class="col-12 col-md-6 text-center text-md-left mt-3 mt-md-0">
                        <h4>Links</h4>
                        <ul class="list-group footer-list-group">
                          
                            <li *ngIf="has_about_us_page === true" class="list-group-item border-0 p-0">
                                <a routerLink="/legal-collection/about-us">About Us</a>
                            </li>
                            <li  *ngIf="has_terms_and_conditions_page === true" class="list-group-item border-0 p-0">
                                <a routerLink="/legal-collection/terms_and_conditions">Terms & Conditions</a>
                            </li>
                            <li *ngIf="has_gallery_page === true" class="list-group-item border-0 p-0">
                                <a routerLink="/gallery">Gallery</a>
                            </li>
                            <li  *ngIf="has_privacy_policy_page === true" class="list-group-item border-0 p-0">
                                <a routerLink="/legal-collection/privacy-policy">Privacy Policy</a>
                            </li>
                           
                            <li class="list-group-item border-0 p-0">
                                <a routerLink="/contact-us/booking_option">Block Booking Enquires</a>
                            </li>
                        </ul>
                    </div>
                </div>
              
            </div>
            <div class="col-12 col-md-12 d-flex justify-content-around justify-content-between">
                <a *ngIf="facebook_url" [href]="facebook_url" class="btn btn-dark">
                    <i class="fab fa-facebook-square mdi-24px mr-0"></i>
                </a>
                <a *ngIf="youtube_url" [href]="youtube_url" class="btn btn-dark">
                    <i class="fab fa-youtube mdi-24px mr-0"></i>
                </a>
                <a *ngIf="instagram_url" [href]="instagram_url" class="btn btn-dark">
                    <i class="fab fa-instagram mdi-24px mr-0"></i>
                </a>
                <a *ngIf="twitter_url" [href]="twitter_url" class="btn btn-dark">
                    <i class="fab fa-twitter mdi-24px mr-0"></i>
                </a>
            </div>
        </div>










        <div id="copyright-footer" class="row mt-3 bg-custom-dark">
            <div class="col text-center text-light py-3">
                &copy; {{appName}} 2024
            </div>
        </div>
    </div>
</footer>