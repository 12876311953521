import { NotyfService } from "./services/notyf/notyf.service";
import { EnvironmentService } from "./services/environment/environment.service";
import { ApiService } from "./services/api/api.service";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { FcmService } from "./services/fcm/fcm.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { CartService } from "./services/cart/cart.service";
import { SettingsService } from "./services/settings/settings.service";
import { Component} from "@angular/core";
import { AlertController, Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Market } from "@ionic-native/market/ngx";
import { Category } from "./interfaces/category";
import { CategoriesService } from "./services/categories/categories.service";

import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";
import { Venue } from "./interfaces/venue";
import { VenuesService } from "./services/venues/venues.service";
declare var cordova: any;

const CART_EXTERNAL_REFRESH_TIMEOUT = 60 * 60 * 1000; // 1 hour;

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  loading = true;
  loadingModal: HTMLIonLoadingElement;
  currentPlatform: string = "";
  cart_timeout: any;

  categories: Category[] = [];
  venuesList: Venue[] = [];

  app_version: string = "1.0.0";
  platform_type: string = 'web';
  

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private settings: SettingsService,
    private auth: AuthService,
    private fcm: FcmService,
    private cartService: CartService,
    private screenOrientation: ScreenOrientation,
    private appVersion: AppVersion,
    private api: ApiService,
    private alertController: AlertController,
    private market: Market,
    private env: EnvironmentService,
    private notyf: NotyfService,
    private categoriesService: CategoriesService,
    private venuesService: VenuesService,
    private firebase: FCM
  ) {
    // set angular to always run ngOnInit when navigating to a page which has already been loaded in current session (e.g view-appointments)
    // this fixes the issue where, for example, new appointments dont show in view appointments when that page has been visited before a new booking was made
    // this.router.onSameUrlNavigation = 'reload';
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.initializeApp();
  }

  async initializeApp() {

    this.api.getApiUrl();

    this.platform.ready().then(async () => {

      this.platform_type = await this.loadPlatform();  
      this.getCategories();
      this.registerCartRefresh(); 
      this.getVenuesList(); 

      if (this.currentPlatform !== "web") {
        if (cordova.platformId === 'android' || this.platform_type ==  'android') {
          this.requestNotificationPermission();
        }
        // lock the screen orientation for mobile apps - saves some css styling xD
        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
        // Get the version from config.xml values.
        // We cant use the ENV values like previous projects since they are now fetched dynamically instead of being hard coded during build.
        
        this.app_version = await this.appVersion.getVersionNumber();

        this.statusBar.styleDefault();
        this.statusBar.overlaysWebView(false);
        this.statusBar.show();
        
        await this.checkVersion();
        this.splashScreen.hide();
      }
      
      
      if (this.currentPlatform !== "web") {
        this.splashScreen.hide();
      }

    });
  }

  registerCartRefresh() {
    this.auth.loggedIn$.subscribe((isLoggedIn) => {
      if (isLoggedIn === true) {
        this.cartService.getCartExternal().catch((err) => {
          // console.log("error getting external cart");
          // console.log({ err });
          this.auth.logout();
          this.notyf.error("You have been logged out.");
        });

        this.cart_timeout = setInterval(() => {
          this.cartService.getCartExternal().catch((err) => {
            // console.log("error getting external cart");
            // console.log({ err });
          });
        }, CART_EXTERNAL_REFRESH_TIMEOUT);
      } else {
        clearInterval(this.cart_timeout);
      }
    });
  }

  async checkVersion() {

    const response = await this.api.get("version-check", {
      version: this.app_version,
      venue_id: this.env.api?.venue_id ?? 1,
      platform: this.currentPlatform,
    }).toPromise();
    
    if (response["valid"] === false) {
      const alert = await this.alertController.create({
        header: "Update Available",
        backdropDismiss: false,
        message: "A newer version of this app is available. Please update to continue.",
        buttons: [
          {
            text: "Update",
            handler: () => {
              this.openAppStore(response["link"]);
              // console.log({ link: response["link"] });
              this.notyf.error({
                message: `Link: ${response["link"]}`,
                duration: 5000,
                dismissible: true,
              });

              // returning false keeps the alert open when the user clicks the button
              return false;
            },
          },
        ],
      });

      await alert.present();
    }
  }

  async loadPlatform() {
    this.currentPlatform = await this.settings.getPlatform();
    return this.currentPlatform;
  }

  async getCategories() {
    this.categories = await this.categoriesService.getCategories();
  }
  
  async getVenuesList() {
    this.venuesList = await this.venuesService.getVenues();
  }
  

  async openAppStore(appId: string) {
    const response = await this.market.open(appId);
  }

  requestNotificationPermission() {
    const permissions = cordova.plugins.permissions;
    permissions.hasPermission(permissions.POST_NOTIFICATIONS, (status) => {
      if (!status.hasPermission) {
        permissions.requestPermission(permissions.POST_NOTIFICATIONS, (status) => {
          if (status.hasPermission) {
            console.log('Notification permission granted');
            this.subscribeToTopic('csa');            
          } else {
            console.error('Notification permission denied');
          }
        });
      } else {
        console.log('Notification permission already granted');
      }
    });
  }

  subscribeToTopic(topic: string) {
    this.firebase.subscribeToTopic(topic).then(() => {
      console.log(`Subscribed to topic: ${topic}`);
    }).catch(error => {
      console.error(`Error subscribing to topic: ${topic}`, error);
    });
  }

  // // Optional: Unsubscribe from a topic
  // unsubscribeFromTopic(topic: string) {
  //   this.firebase.unsubscribeFromTopic(topic).then(() => {
  //     console.log(`Unsubscribed from topic: ${topic}`);
  //   }).catch(error => {
  //     console.error(`Error unsubscribing from topic: ${topic}`, error);
  //   });
  // }
}

