import { Component, ElementRef, OnInit, Renderer2, Input, EventEmitter, Output, Inject, SimpleChanges, ChangeDetectionStrategy } from "@angular/core";
import { DOCUMENT } from '@angular/common'; 

@Component({
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss'],
})


export class RangeSliderComponent implements OnInit {


  

  @Input("defaultValues") defaultValues;
  @Input("newSelectionValues") newSelectionValues;
  @Input("interval") interval;
  @Input("step") step;
  @Output() onRangeChange: EventEmitter<any> = new EventEmitter();

  changeDetection: ChangeDetectionStrategy.OnPush

  position_min = 0;
  position_max = 0;
  currentValue = 0;
  _defaultValues = { min: 0, max: 0 };
  _newSelectionValues = { min: 0, max: 0 };

  slider: any = null;
  parent: any = null;

  selectedRange
  wrapper: any;
  sliderContainer: any;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2, //private control: NgControl
    @Inject(DOCUMENT) document: Document
  ) { }



  
  ngOnChanges(changes: SimpleChanges){

    let wrapperElement = document.getElementById('sliderContainer')

    let parentElement = document.getElementById('timeRangeWrapper')

    // console.log(wrapperElement)

    if (parentElement && wrapperElement) {
      
      wrapperElement.remove();
      
      this.createHtmlTemplate();
      
    }else{

      this.createHtmlTemplate();
    }
    
  }

  createHtmlTemplate(){

    this.sliderContainer = this.renderer.createElement("div");

    this.renderer.setProperty(this.sliderContainer, 'id', 'sliderContainer');

    this.wrapper = this.renderer.createElement("div");

    this.renderer.setProperty(this.wrapper, 'id', 'timeRangeWrapper');
    
    this.slider = this.renderer.createElement("div");

    this.renderer.setProperty(this.slider, 'id', 'child');

    this.selectedRange = {min: this.timeTransformToHHMM(this.defaultValues.min) , max: this.timeTransformToHHMM(this.defaultValues.max)};
    
    this.renderer.addClass(this.slider, "slider");

    const track = this.renderer.createElement("div");
    this.renderer.addClass(track, "track");
    this.renderer.setAttribute(track, "default-min", this.defaultValues.min);
    this.renderer.setAttribute(track, "default-max", this.defaultValues.max);

    const range = this.renderer.createElement("div");
    this.renderer.addClass(range, "range");

    
    const thumb_left = this.renderer.createElement("div");
    this.renderer.addClass(thumb_left, "thumb");
    this.renderer.addClass(thumb_left, "left");
    this.renderer.setAttribute(
      thumb_left,
      "data-min",
      this.newSelectionValues.min
    );
    this.renderer.setAttribute(
      thumb_left,
      "data-min-time",
      this.timeTransformToHHMM(this.newSelectionValues.min)
    );
    var percent_left =
      (((this.newSelectionValues.min) - this.defaultValues.min) /
        (this.defaultValues.max - this.defaultValues.min)) *
      100;
    thumb_left.style.left = percent_left + "%";
    range.style.left = (percent_left) + "%";

    const thumb_right = this.renderer.createElement("div");
    this.renderer.addClass(thumb_right, "thumb");
    this.renderer.addClass(thumb_right, "right");
    this.renderer.setAttribute(
      thumb_right,
      "data-max",
      this.newSelectionValues.max
    );
    this.renderer.setAttribute(
      thumb_right,
      "data-max-time",
      this.timeTransformToHHMM(this.newSelectionValues.max)
    );
    var percent_right =
      ((this.newSelectionValues.max - this.defaultValues.min) /
        (this.defaultValues.max - this.defaultValues.min)) *
      100;
    thumb_right.style.right = 100 - percent_right + "%";
    range.style.right = 100 - percent_right + "%";

    this.renderer.appendChild(this.slider, track);
    this.renderer.appendChild(this.slider, range);
    
    this.renderer.appendChild(this.slider, thumb_left);
    this.renderer.appendChild(this.slider, thumb_right);

    const range_left = this.renderer.createElement("input");
    this.renderer.setAttribute(range_left, "type", "range");
    this.renderer.setAttribute(range_left, "min", this.defaultValues.min);
    this.renderer.setAttribute(range_left, "max", this.defaultValues.max);
    this.renderer.setAttribute(range_left, "step", this.step);
    this.renderer.addClass(range_left, "input-left");
    this.renderer.setAttribute(
      range_left,
      "value",
      this.newSelectionValues.min
    );

    const range_right = this.renderer.createElement("input");
    this.renderer.setAttribute(range_right, "type", "range");
    this.renderer.setAttribute(range_right, "min", this.defaultValues.min);
    this.renderer.setAttribute(range_right, "max", this.defaultValues.max);
    this.renderer.setAttribute(range_right, "step", this.step);
    this.renderer.addClass(range_right, "input-right");
    this.renderer.setAttribute(
      range_right,
      "value",
      this.newSelectionValues.max
    );

    this.elementRef.nativeElement.style = "display:none";
    this.renderer.appendChild(
      this.wrapper,
      range_left
    );
    this.renderer.appendChild(
      this.wrapper,
      range_right
    );
    this.renderer.appendChild(
      this.wrapper,
      this.slider
    );

    this.renderer.appendChild(
      this.sliderContainer,
      this.wrapper
    );

    this.renderer.appendChild(
      this.renderer.parentNode(this.elementRef.nativeElement),
      this.sliderContainer
    );


    this.renderer.listen(range_left, "input", event => {
      
      thumb_right.style.zIndex = 4;
      thumb_left.style.zIndex = 5;
      range_right.style.zIndex = 4;
      range_left.style.zIndex = 5;

      let min = event.target.min;
      let max = event.target.max;
      let position = event.target.value;
      
      if (((range_right.value * 1)- (range_left.value * 1)) < this.interval) {

        var percent = ((((range_right.value * 1)- this.interval) - min) / (max - min)) * 100;
        thumb_left.style.left = percent + "%";
        range.style.left = percent + "%";
        range_left.value = (range_right.value * 1)- this.interval;
        event.preventDefault();
        return false;

      }else{

        this.selectedRange.min = this.timeTransformToHHMM(range_left.value);
        this.onRangeChange.emit(this.selectedRange);
        thumb_left.setAttribute("data-min", +event.target.value);
        thumb_left.setAttribute("data-min-time", this.timeTransformToHHMM(event.target.value));
        var percent = ((position - min) / (max - min)) * 100;
        thumb_left.style.left = percent + "%";
        range.style.left = percent + "%";      }

    });

    this.renderer.listen(range_right, "input", event => {
      
      thumb_left.style.zIndex = 4;
      thumb_right.style.zIndex = 5;
      range_left.style.zIndex = 4;
      range_right.style.zIndex = 5;
      let min = +event.target.min;
      let max = +event.target.max;
      let position = +event.target.value;

      if ( ((range_right.value * 1)- (range_left.value * 1)) < this.interval) {
        var percent = (((range_left.value *1) + this.interval) - min)  / (max - min) * 100;
        thumb_right.style.right = 100 - percent + "%";
        range.style.right = 100 - percent + "%";
        range_right.value = (range_left.value * 1) + this.interval;
        event.preventDefault();
        return false;

      }else{

        this.selectedRange.max = this.timeTransformToHHMM(range_right.value);
        this.onRangeChange.emit(this.selectedRange);
        thumb_right.setAttribute("data-max", +event.target.value);
        thumb_right.setAttribute("data-max-time", this.timeTransformToHHMM(event.target.value));
        var percent = ((position - min) / (max - min)) * 100;
        thumb_right.style.right = 100 - percent + "%";
        range.style.right = 100 - percent + "%";
      }

    });


  }
  

  ngOnInit() {

    let wrapperElement = document.getElementById('sliderContainer');

    if(wrapperElement == null){
      this.createHtmlTemplate();
    }

    

  }

  timeTransformToHHMM(value: any): string {

    let str_hours: any;
    let str_minutes: any;

    const hours = Math.floor(value / 60);
    const minutes = value % 60;
    str_hours = hours;
    str_minutes = minutes;

    if(hours * 1 < 10){
      str_hours = `0${hours}`;
    }

    if(minutes * 1 == 0){
      str_minutes = `0${minutes}`
    }
    
    return `${str_hours}:${str_minutes}`;
  }







  // changeDetection: ChangeDetectionStrategy.OnPush
  
  // @Input("defaultValues") defaultValues;
  // @Input("newSelectionValues") newSelectionValues;
  // @Input("interval") interval;
  // @Output() onRangeChange: EventEmitter<any> = new EventEmitter();
  // position_min = 0;
  // position_max = 0;
  // currentValue = 0;
  // selectedRange: any;

  // slider: any;
  // track: any;
  // range: any;
  // thumb_left: any;
  // thumb_right: any;
  // range_right: any;
  // range_left: any;

  // constructor(
  //   private elementRef: ElementRef,
  //   private renderer: Renderer2,//private control: NgControl,
  //   @Inject(DOCUMENT) document: Document
  // ) { 

  //   this.slider = document.getElementById('slider');
  //   this.track = document.getElementById('track');
  //   this.range = document.getElementById('range');
  //   this.range_left = document.getElementById('range_left');
  //   this.range_right = document.getElementById('range_right');
  //   this.thumb_left = document.getElementById('thumb_left');
  //   this.thumb_right = document.getElementById('thumb_right');

  // }



  // ngOnInit() {

  //   this.updateTimeRange();

    
  // }

  // ngOnChanges(changes: SimpleChanges){
    
  //   this.updateTimeRange();

  // }


  // updateTimeRange() {

  //   let html = '<input id="range_left" type="range" class="input-left" >';
  //   html += '<input id="range_right" type="range" class="input-right" >';
  //   html += '<div id="slider" class="slider">';
  //   html += '<div id="track" class="track" ></div>';
  //   html += '<div id="range" class="range"></div>';
  //   html += '<div id="thumb_left" class="thumb left"></div>';
  //   html += '<div id="thumb_right" class="thumb right"></div>';
  //   html += '</div>';

  //   let sliderContainer = document.getElementById('sliderContainer');
  //   // console.log(sliderContainer);

  //   if (sliderContainer && sliderContainer.childElementCount != 0 ) {
      
  //     this.renderer.removeChild(sliderContainer, sliderContainer.children);

  //     //this.renderer.appendChild(sliderContainer, html);
      
  //   }

  //   this.selectedRange = {min: this.timeTransformToHHMM(this.defaultValues.min) , max: this.timeTransformToHHMM(this.defaultValues.max)};

  //   this.slider = document.getElementById('slider');
  //   this.track = document.getElementById('track');
  //   this.range = document.getElementById('range');
  //   this.range_left = document.getElementById('range_left');
  //   this.range_right = document.getElementById('range_right');
  //   this.thumb_left = document.getElementById('thumb_left');
  //   this.thumb_right = document.getElementById('thumb_right');
    
  //   this.renderer.setAttribute(this.track, "default-min", this.defaultValues.min);
  //   this.renderer.setAttribute(this.track, "default-max", this.defaultValues.max);
   
  //   this.renderer.setAttribute(
  //     this.thumb_left, 
  //     "data-min", 
  //     this.newSelectionValues.min
  //   );

  //   this.renderer.setAttribute(
  //     this.thumb_left, 
  //     "data-min-time", 
  //     this.timeTransformToHHMM(this.newSelectionValues.min)
  //   );
    
  //   var percent_left =
  //     ((this.newSelectionValues.min - this.defaultValues.min) /
  //       (this.defaultValues.max - this.defaultValues.min)) *
  //     100;

  //   this.thumb_left.style.left = percent_left + "%";
  //   this.range.style.left = percent_left + "%";

   
  //   this.renderer.setAttribute(this.thumb_right, "data-max", this.newSelectionValues.max);
  //   this.renderer.setAttribute
  //   (this.thumb_right, 
  //     "data-max-time", 
  //     this.timeTransformToHHMM(
  //     this.newSelectionValues.max)
  //   );
    
  //   var percent_right =
  //     ((this.newSelectionValues.max - this.defaultValues.min) /
  //       (this.defaultValues.max - this.defaultValues.min)) *
  //     100;

  //   this.thumb_right.style.right = 100 - percent_right + "%";
  //   this.range.style.right = 100 - percent_right + "%";

  //   this.renderer.setAttribute(this.range_left, "min", this.defaultValues.min);
  //   this.renderer.setAttribute(this.range_left, "max", this.defaultValues.max);
  //   this.renderer.setAttribute(this.range_left, "step", this.step);
  //   this.renderer.setAttribute(this.range_left, "value", this.newSelectionValues.min);

    
  //   this.renderer.setAttribute(this.range_right, "min", this.defaultValues.min);
  //   this.renderer.setAttribute(this.range_right, "max", this.defaultValues.max);
  //   this.renderer.setAttribute(this.range_right, "step", this.step);
  //   this.renderer.setAttribute(this.range_right,"value", this.newSelectionValues.max);

  //   this.renderer.listen(this.range_left, "input", event => {
      
  //     this.thumb_right.style.zIndex = 4;
  //     this.thumb_left.style.zIndex = 5;
  //     this.range_right.style.zIndex = 4;
  //     this.range_left.style.zIndex = 5;

  //     let min = event.target.min;
  //     let max = event.target.max;
  //     let position = event.target.value;
      
  //     if (((this.range_right.value * 1)- (this.range_left.value * 1)) < this.interval) {

  //       var percent = ((((this.range_right.value * 1)- this.interval) - min) / (max - min)) * 100;
  //       this.thumb_left.style.left = percent + "%";
  //       this.range.style.left = percent + "%";
  //       this.range_left.value = (this.range_right.value * 1)- this.interval;
  //       event.preventDefault();
  //       return false;

  //     }else{

  //       this.selectedRange.min = this.timeTransformToHHMM(this.range_left.value);
  //       this.onRangeChange.emit(this.selectedRange);
  //       this.thumb_left.setAttribute("data-min", +event.target.value);
  //       this.thumb_left.setAttribute("data-min-time", this.timeTransformToHHMM(event.target.value));
  //       var percent = ((position - min) / (max - min)) * 100;
  //       this.thumb_left.style.left = percent + "%";
  //       this.range.style.left = percent + "%";      }

  //   });

  //   this.renderer.listen(this.range_right, "input", event => {
      
  //     this.thumb_left.style.zIndex = 4;
  //     this.thumb_right.style.zIndex = 5;
  //     this.range_left.style.zIndex = 4;
  //     this.range_right.style.zIndex = 5;
  //     let min = +event.target.min;
  //     let max = +event.target.max;
  //     let position = +event.target.value;

  //     if ( ((this.range_right.value * 1)- (this.range_left.value * 1)) < this.interval) {
  //       var percent = (((this.range_left.value *1) + this.interval) - min)  / (max - min) * 100;
  //       this.thumb_right.style.right = 100 - percent + "%";
  //       this.range.style.right = 100 - percent + "%";
  //       this.range_right.value = (this.range_left.value * 1) + this.interval;
  //       event.preventDefault();
  //       return false;

  //     }else{

  //       this.selectedRange.max = this.timeTransformToHHMM(this.range_right.value);
  //       this.onRangeChange.emit(this.selectedRange);
  //       this.thumb_right.setAttribute("data-max", +event.target.value);
  //       this.thumb_right.setAttribute("data-max-time", this.timeTransformToHHMM(event.target.value));
  //       var percent = ((position - min) / (max - min)) * 100;
  //       this.thumb_right.style.right = 100 - percent + "%";
  //       this.range.style.right = 100 - percent + "%";
  //     }

  //   });

    
  // }
    
  // timeTransformToHHMM(value: any): string {

  //   let str_hours: any;
  //   let str_minutes: any;

  //   const hours = Math.floor(value / 60);
  //   const minutes = value % 60;
  //   str_hours = hours;
  //   str_minutes = minutes;

  //   if(hours * 1 < 10){
  //     str_hours = `0${hours}`;
  //   }

  //   if(minutes * 1 == 0){
  //     str_minutes = `0${minutes}`
  //   }
    
  //   return `${str_hours}:${str_minutes}`;
  // }
}
