// venues.service.ts
import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Venue } from 'src/app/interfaces/venue';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VenuesService {

  private venuesSubject: BehaviorSubject<Venue[]> = new BehaviorSubject<Venue[]>([]);
  public venues$: Observable<Venue[]> = this.venuesSubject.asObservable();

  constructor(private api: ApiService) {}

  async fetchVenues() {
    try {
      let data: any = {};
     
      const response = await this.api.get('/venues').toPromise();
      if (response['success']) {
        // console.log('fetchVenues =======> ', response);
        this.venuesSubject.next(response['data']);
      } else {
        console.error('Error fetching venues:', response);
      }
    } catch (error) {
      console.error('API request failed:', error);
    }
  }

  // async getVenues() {
    
  //   await this.fetchVenues();

  //   return this.venuesSubject.getValue();
  // }

  
  async getVenues(): Promise<Venue[]> {
    
    const currentVenues = this.venuesSubject.getValue();

    if (!currentVenues || currentVenues.length === 0) {
      // Fetch venues only if the current list is empty
      await this.fetchVenues();
    }

    return this.venuesSubject.getValue();
  }
}
