<div class="modal-header">
  <h6 class="modal-title page-header-title">{{ service.name }}</h6>
  <button id="close_btn" type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span class="fas fa-times"></span>
  </button>
</div>
<div *ngIf="service.image_url" class="modal-body p-0 mt-1">
  <img width="80%" [src]="service.image_url" [alt]="service.name" class="img-fluid d-block mx-auto">
  <p class="mr-2 ml-2 mt-2" style="text-align:center;">{{ service.full_description }}</p>
</div>
<div class="modal-footer">
  <div class="row w-100 align-items-center">
    <div class="col-4 d-flex justify-content-start align-items-center"><i class="fas fa-money-bill mr-2"></i> {{ service.price | currency}}</div>
    <div class="col-4 d-flex justify-content-center align-items-center"><i class="far fa-clock mr-2"></i> <small>{{ service.time_taken }}</small></div>
    <div class="col-4 d-flex justify-content-end">
      <button type="button" class="btn btn-outline-primary btn-sm" (click)="activeModal.close('book-now')">Book Now</button>
    </div>
  </div>
</div>