<section class="d-none d-md-block">
    <div id="hero-search-bar" class="carousel slide carousel-fade" data-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item active">
                <img [src]="image_url" class="d-block" alt="...">
                <div class="black-overlay"></div>
                <div class="black-overlay"></div>
                <div class="black-overlay"></div>
            </div>
        </div>
        <div class="carousel-caption d-none d-md-block mt-n5 text-light">
            <h1 class="text-light">{{ title }}</h1>
            <p><i>{{ subtitle }}</i></p>
        </div>
    </div>
</section>
