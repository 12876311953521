import { NotyfService } from "src/app/services/notyf/notyf.service";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { Category } from "src/app/interfaces/category";
import { CategoriesService } from "src/app/services/categories/categories.service";
import { SettingsService } from "./../../services/settings/settings.service";
import { CartService } from "./../../services/cart/cart.service";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AlertController, NavController, Platform, ViewDidEnter, ViewWillEnter } from "@ionic/angular";
import { filter } from "rxjs/operators";
import { AuthService } from "src/app/services/auth/auth.service";
import * as moment from "moment";
import { EnvironmentService } from "src/app/services/environment/environment.service";
import { threadId } from "worker_threads";
import { ApiService } from "src/app/services/api/api.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, ViewWillEnter{
  offcanvasOpen = false;
  menuShown = false;
  loggedIn = false;
  currentPlatform: string = "";
  currentRoute: string = "";

  cart = [];
  cart_empty = true;
  firstRoute = true;

  @Input() mobileHeader = true;
  @Input() go_back_string: string = null;
  @Input() button : any = null;

  copyright_year;
  app_logo;
  has_about_us_page = false;
  has_gallery_page = false;
  has_terms_and_conditions_page: boolean = false;
  has_privacy_policy_page: boolean = false;
  show_services_dropdown = false;
  allow_close_services_dropdown = false;
  client_app = false;
  venue_name = '';
  categories: Category[] = [];
  positionAbsolute = false;
  mobile_app = false;
  on_mainApp: boolean = false;
  

  @ViewChild("mainNav") mainNav: ElementRef;
  @ViewChild("offcanvasFooter") offcanvasFooter: ElementRef;

  app_version: string;
 

  get cartCount() {
    return this.cart.length;
  }

  constructor(
    private alertController: AlertController,
    public auth: AuthService,
    private navController: NavController,
    private router: Router,
    private cartService: CartService,
    private settingsService: SettingsService,
    private env: EnvironmentService,
    private categoriesService: CategoriesService,
    private platform: Platform,
    private appVersion: AppVersion,
    private notyf: NotyfService,
    private api: ApiService,
  ) {

    this.on_mainApp = this.env.api.venue_id === null;
    this.app_logo = this.env?.settings?.venue?.venue_logo;
    this.initi();
  }

  async initi(){

    this.auth.loggedIn$.subscribe((isLoggedIn: boolean) => {
      this.loggedIn = isLoggedIn;
    });

    this.currentPlatform = await this.settingsService.getPlatform();

    this.router.events
      // Here we are passing all events through the pipe. The filter will only return NavigationEnd events
      .pipe(filter((e) => e instanceof NavigationEnd))
      // Get the current route from the event
      .subscribe((event: NavigationEnd) => {
        if (this.currentRoute !== undefined) {
          if (this.firstRoute && document.referrer.indexOf(window.location.hostname) === -1) {
            this.firstRoute = false;
          }
        }

        this.currentRoute = event.url;

        this.closeOffcanvasNav();
      });

    // listening for changes to the cart
    this.cartService.cart$.subscribe((cart) => {
      this.cart = cart;

      if (this.cart.length > 0) {
        this.cart_empty = false;
      } else {
        this.cart_empty = true;
      }
    });

    this.copyright_year = moment().format("YYYY");

    if(this.on_mainApp){

      const mainAppSettings = await this.api.post('/settings/main-app-settings').toPromise();

      if(mainAppSettings){
        let mainData = mainAppSettings['data'];
        let mainAppVenue = mainData.venue;
        let mainSettings = mainData.app;
        this.app_logo = mainAppVenue.venue_logo;
        this.has_about_us_page = mainSettings.has_about_us_page === "yes";
        this.has_gallery_page = mainSettings.has_gallery_page === "yes";
        this.has_terms_and_conditions_page = mainSettings.has_terms_and_conditions_page === "yes";
        this.has_privacy_policy_page = mainSettings.has_privacy_policy_page === "yes";
        
      }

    }else{

      this.app_logo = this.settingsService.getSetting("venue", "venue_logo");
      
      this.settingsService.settings$.subscribe((response) => {
        this.app_logo = response.venue.venue_logo;
        this.has_about_us_page = response.app.has_about_us_page === "yes";
        this.has_gallery_page = response.app.has_gallery_page === "yes";
        this.has_terms_and_conditions_page = response.app.has_terms_and_conditions_page === "yes";
        this.has_privacy_policy_page = response.app.has_privacy_policy_page === "yes";        
      });
    }    

    if (this.env.api.venue_id !== null) {
      this.client_app = true;
    }
    
    if (this.env.settings.venue.venue_name !== null) {
      this.venue_name = this.env.settings.venue.venue_name;
    }

    this.categoriesService.categories$.subscribe((categories) => {
      this.categories = categories;
    });

    const platform = await this.settingsService.getPlatform();

    this.mobile_app = platform === "android" || platform === "ios";

    if (this.mobile_app) {
      this.platform.ready().then(async () => {
        try {
          this.app_version = await this.appVersion.getVersionNumber();
        } catch (error) {
          this.notyf.error({
            message: "There was an error getting the version number: " + error,
            duration: 0,
            dismissible: true,
          });
        }
      });
    }
    
  }

  ionViewWillEnter(){
    this.on_mainApp = this.env.api.venue_id === null;    
  }

  ngOnInit() {
    this.initi()
  }

  toggleOffcanvasNav() {
    this.offcanvasOpen = !this.offcanvasOpen;
  }

  closeOffcanvasNav() {
    this.offcanvasOpen = false;

    this.calculatePositionAbsolute();
  }

  openOffcanvasNav() {
    this.offcanvasOpen = true;

    this.calculatePositionAbsolute();
  }

  private calculatePositionAbsolute() {
    const totalHeight = this.mainNav.nativeElement.scrollHeight;

    if (totalHeight <= this.platform.height()) {
      this.positionAbsolute = true;
    } else {
      this.positionAbsolute = false;
    }
  }

  async logout() {

    this.auth.logout();

    const alert = await this.alertController.create({
      header: "Success",
      message: "You have been logged out successfully!",
      buttons: [
        {
          text: "OK",
          handler: () => {
            this.navController.navigateRoot("/");
          },
        },
      ],
    });

    await alert.present();
  }

  goBack() {
    this.navController.back();
  }

  closeServicesDropdown(category: Category = null) {
    if (this.allow_close_services_dropdown === true) {
      this.show_services_dropdown = false;
      this.allow_close_services_dropdown = false;
    }

    if (category !== null) {
      this.categoriesService.setActiveCategory(category);
    }

    this.calculatePositionAbsolute();
  }

  openServicesDropdown() {
    this.show_services_dropdown = true;

    setTimeout(() => {
      this.calculatePositionAbsolute();
    }, 0);
    // this.calculatePositionAbsolute();
  }

  toggleServicesDropdown() {
    this.show_services_dropdown = !this.show_services_dropdown;

    if (this.show_services_dropdown === true) {
      setTimeout(() => {
        this.allow_close_services_dropdown = true;
      }, 100);
    }

    setTimeout(() => {
      this.calculatePositionAbsolute();
    }, 0);
    // this.calculatePositionAbsolute();
  }

  openSearch() {
    //
  }
}
