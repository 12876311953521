import { AuthService } from "src/app/services/auth/auth.service";
import { ApiService } from "src/app/services/api/api.service";
import { NotyfService } from "./../notyf/notyf.service";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { CartItem } from "src/app/interfaces/cart-item";
import * as moment from "moment";

const CLEAR_CART_TIMEOUT = 24 * 60 * 60 * 1000;

@Injectable({
  providedIn: "root",
})
export class CartService {

  settings: any;
  accept_booking_at_current_slot: any ;
  private _cart: CartItem[] = [];
  private _oldCart: CartItem[] = [];
  private _overwriteCart: CartItem[] = [];
  private _oldCartSet: boolean = false;
  cart$: Observable<any>;
  private cartSubject: BehaviorSubject<any>;
  private cart_timeout = null;
  private loggedIn: boolean = false;
  private externalCartId: number = null;
  venue_accept_booking_at_current_slot: string = 'yes';

  constructor(private router: Router, private notyf: NotyfService, private api: ApiService) {

    this._cart = this.getCartFromStorage();
    this.cartSubject = new BehaviorSubject<any>(this._cart);
    this.cart$ = this.cartSubject.asObservable();
   
  }

  async getCartVenueSettings(){
   
  }

  addBooking(
    service_id: number,
    service_name: string,
    employee_id: number,
    employee_name: string,
    date: string,
    time: string,
    price: number,
    venue_id: number,
    venue_name: string,
    notes: string,
    time_taken: any = null,
    next_slot: any = null,
    pre_slot: any= null,
  ) {
    const booking = {
      service_id,
      service_name,
      employee_id,
      employee_name,
      date,
      time,
      price,
      added: new Date(),
      venue_id,
      venue_name,
      notes,
      time_taken,
      next_slot,
      pre_slot,
    };

    let cart = this._cart;

    if (cart.length === 0) {
      cart = this.getCartFromStorage();
    }

    cart.push(booking);
    
    localStorage.setItem("cart", JSON.stringify(cart));

    this.cartSubject?.next(cart);

    this.resetTimeout();

    return cart;
  }

  addItemExternal(booking: CartItem) {
    console.log(booking);
    this.api.post("/cart/add-item", booking).subscribe((response) => this.handleExternalResponse(response));
  }

  private handleExternalResponse(response) {
    if (response["success"] === true) {
      if (typeof response["data"] !== "undefined") {
        this._cart = response["data"]["items"];
        this.cartSubject?.next(this._cart);
        localStorage.setItem("cart", JSON.stringify(this._cart));
        if (response["data"]["deleted_items"]) {
          response["data"]["deleted_items"].forEach((item: CartItem) => {
            const booking_date = moment(`${item.date} ${item.time}`, "YYYY-MM-DD HH:mm");

            this.notyf.open({
              type: "info",
              message: `Your appointment for a ${item.service_name} at ${item.venue_name} on ${booking_date.format(
                "ddd Do MMM"
              )} at ${booking_date.format("H:mm A")} has expired. It has been removed from your cart.`,
              dismissible: true,
              duration: 1000 * 10, // 10 seconds
            });
          });
        }
      }
    }
  }

  private resetTimeout() {
    if (this.cart_timeout !== null) {
      clearTimeout(this.cart_timeout);
    }

    if (this.cartSubject.value.length > 0) {
      this.cart_timeout = setTimeout(() => {
        this.clearCart();
        this.notyf.open({
          type: "info",
          message: "Your cart has been emptied due to inactivity.",
          dismissible: true,
          duration: 0,
        });
      }, CLEAR_CART_TIMEOUT);
    }
  }

  public getCartFromStorage() {

    return JSON.parse(localStorage.getItem("cart")) || [];

  }

  getCart() {

    let cart = this.cartSubject.getValue();

    this.venue_accept_booking_at_current_slot = localStorage.getItem("accept_booking_at_current_slot");

    if (cart.length === 0) {
      cart = this.getCartFromStorage();
    }

    const now = moment();

    cart.forEach((item: CartItem, index: number) => {

      if (item != null) {

        let booking_date = moment(`${item.date} ${item.time}`, "YYYY-MM-DD HH:mm");

        let temp_booking_date = booking_date;

        if(this.venue_accept_booking_at_current_slot == 'yes'){

          if(item.next_slot != null){
            
            temp_booking_date = moment(`${item.date} ${item.next_slot}`, "YYYY-MM-DD HH:mm");

          }else{
            
            temp_booking_date = temp_booking_date.add(item.time_taken, 'minutes')

          }
        }
        // console.log('/////////////////////////////////////////////////////////////////////////')
        // console.log('now ===========> ', now.format("YYYY-MM-DD HH:mm"))
        // console.log('booking_date ===============> ',  booking_date.format("YYYY-MM-DD HH:mm"))
        // console.log('temp_booking_date ===============> ',  temp_booking_date.format("YYYY-MM-DD HH:mm"))
        // console.log('temp_booking_date.isSameOrBefore(now) ===============> ',  temp_booking_date.isSameOrBefore(now))
        // console.log('/////////////////////////////////////////////////////////////////////////')
        
        if(temp_booking_date.isSameOrBefore(now)) {
          this.removeItemExternal(index);
          this.removeItem(index); // will update the cart across the app, with the exception of the current cart array
          delete cart[index]; // will only affect the array we are currently looping over

          this.notyf.open({
            type: "info",
            message: `Your appointment for a ${item.service_name} at ${item.venue_name} on ${booking_date.format(
              "ddd Do MMM"
            )} at ${booking_date.format("H:mm A")} has expired. It has been removed from your cart.`,
            dismissible: true,
            duration: 1000 * 10, // 10 seconds
          });
        }
        
      }else{
        this.removeItem(index);
      }
    });

    if (cart.length > 0) {
      this.resetTimeout();
    }

    return cart;
  }

  async getCartExternal() {
    const response = await this.api.get("/cart").toPromise();

    if (response["success"] === true) {

      const cart = response["data"]["items"];
      localStorage.setItem("accept_booking_at_current_slot", response["data"]["accept_booking_at_current_slot"]);
      if (response["data"]["deleted_items"]) {
        response["data"]["deleted_items"].forEach((item: CartItem) => {
          const booking_date = moment(`${item.date} ${item.time}`, "YYYY-MM-DD HH:mm");

          this.notyf.open({
            type: "info",
            message: `Your appointment for a ${item.service_name} at ${item.venue_name} on ${booking_date.format(
              "ddd Do MMM"
            )} at ${booking_date.format("H:mm A")} has expired. It has been removed from your cart.`,
            dismissible: true,
            duration: 1000 * 10, // 10 seconds
          });
        });
      }
      this._cart = cart;
      this.cartSubject?.next(this._cart);
      localStorage.setItem("cart", JSON.stringify(this._cart));

      return cart;
    }

    return false;
  }

  saveCartExternal() {
    this.api.post("/cart/save-cart", { items: this.getCart() }).subscribe(this.handleExternalResponse);
  }

  overwriteCart(cart: CartItem[]) {
    if (this._cart.length > 0 && this._oldCartSet === false) {
      this._oldCart = this._cart;
      this._oldCartSet = true;
    }

    if (!(this._overwriteCart == cart)) {
      this._cart = cart;
      this._overwriteCart = cart;

      this.cartSubject?.next(this._cart);
    }

    this.resetTimeout();
  }

  removeItem(index: number) {
    const cart = this.cartSubject?.getValue();

    if (cart[index]) {
      cart.splice(index, 1);
    }

    localStorage.setItem("cart", JSON.stringify(cart));
    this.cartSubject?.next(cart);

    this.resetTimeout();

    return cart;
  }

  removeItemExternal(index: number) {
    this.api.post("/cart/remove-item", { index }).subscribe(this.handleExternalResponse);
  }

  clearCart() {
    this._cart = [];

    localStorage.setItem("cart", JSON.stringify(this._cart));
    localStorage.removeItem("paymentIntentId");

    this.cartSubject?.next(this._cart);

    this.resetTimeout();

    return this._cart;
  }

  clearCartExternal() {
    return this.api.post("/cart/clear-cart").toPromise();
  }

  checkIfOnCheckoutPage() {
    return this.router.url === "/checkout";
  }
}


// import { AuthService } from "src/app/services/auth/auth.service";
// import { ApiService } from "./../api/api.service";
// import { NotyfService } from "./../notyf/notyf.service";
// import { Router } from "@angular/router";
// import { Injectable } from "@angular/core";
// import { Observable, BehaviorSubject } from "rxjs";
// import { CartItem } from "src/app/interfaces/cart-item";
// import * as moment from "moment";
// import { HttpClient, HttpHeaders } from "@angular/common/http";
// import { EnvironmentService } from "../environment/environment.service";

// const CLEAR_CART_TIMEOUT = 24 * 60 * 60 * 1000;

// @Injectable({
//   providedIn: "root",
// })
// export class CartService {

//   settings: any;
//   accept_booking_at_current_slot: any ;
//   private _cart: CartItem[] = [];
//   private _oldCart: CartItem[] = [];
//   private _overwriteCart: CartItem[] = [];
//   private _oldCartSet: boolean = false;
//   cart$: Observable<any>;
//   private cartSubject: BehaviorSubject<any>;
//   private cart_timeout = null;
//   private loggedIn: boolean = false;
//   private externalCartId: number = null;
//   venue_accept_booking_at_current_slot: string = 'yes';
//   headers = {};

//   constructor(
//     private router: Router,
//     private notyf: NotyfService,
//     private api: ApiService,
//     private http: HttpClient,
//     private env: EnvironmentService,
//   ) {
    
//     this.headers = new HttpHeaders({});
//     this._cart = this.getCartFromStorage();
//     this.cartSubject = new BehaviorSubject<any>(this._cart);
//     this.cart$ = this.cartSubject.asObservable();
   
//   }

//   async getCartVenueSettings(){
   
//   }

//   addBooking(
//     service_id: number,
//     service_name: string,
//     employee_id: number,
//     employee_name: string,
//     date: string,
//     time: string,
//     price: number,
//     venue_id: number,
//     venue_name: string,
//     notes: string,
//     time_taken: any = null,
//     next_slot: any = null,
//     pre_slot: any= null,
//   ) {
//     const booking = {
//       service_id,
//       service_name,
//       employee_id,
//       employee_name,
//       date,
//       time,
//       price,
//       added: new Date(),
//       venue_id,
//       venue_name,
//       notes,
//       time_taken,
//       next_slot,
//       pre_slot,
//     };

//     let cart = this._cart;

//     if (cart.length === 0) {
//       cart = this.getCartFromStorage();
//     }

//     cart.push(booking);
    
//     localStorage.setItem("cart", JSON.stringify(cart));

//     this.cartSubject?.next(cart);

//     this.resetTimeout();

//     return cart;
//   }

//   addItemExternal(booking: CartItem) {
//     console.log(booking);
//     this.post("/cart/add-item", booking).subscribe((response) => this.handleExternalResponse(response));
//   }

//   private handleExternalResponse(response) {
//     if (response["success"] === true) {
//       if (typeof response["data"] !== "undefined") {
//         this._cart = response["data"]["items"];
//         this.cartSubject?.next(this._cart);
//         localStorage.setItem("cart", JSON.stringify(this._cart));
//         if (response["data"]["deleted_items"]) {
//           response["data"]["deleted_items"].forEach((item: CartItem) => {
//             const booking_date = moment(`${item.date} ${item.time}`, "YYYY-MM-DD HH:mm");

//             this.notyf.open({
//               type: "info",
//               message: `Your appointment for a ${item.service_name} at ${item.venue_name} on ${booking_date.format(
//                 "ddd Do MMM"
//               )} at ${booking_date.format("H:mm A")} has expired. It has been removed from your cart.`,
//               dismissible: true,
//               duration: 1000 * 10, // 10 seconds
//             });
//           });
//         }
//       }
//     }
//   }

//   private resetTimeout() {
//     if (this.cart_timeout !== null) {
//       clearTimeout(this.cart_timeout);
//     }

//     if (this.cartSubject.value.length > 0) {
//       this.cart_timeout = setTimeout(() => {
//         this.clearCart();
//         this.notyf.open({
//           type: "info",
//           message: "Your cart has been emptied due to inactivity.",
//           dismissible: true,
//           duration: 0,
//         });
//       }, CLEAR_CART_TIMEOUT);
//     }
//   }

//   public getCartFromStorage() {

//     return JSON.parse(localStorage.getItem("cart")) || [];

//   }

//   public getUrl() {

//     console.log("getUrl =========> ",localStorage.getItem("url") || this.env.api.url)

//     return localStorage.getItem("url") || this.env.api.url;

//   }

//   getCart() {

//     let cart = this.cartSubject.getValue();

//     this.venue_accept_booking_at_current_slot = localStorage.getItem("accept_booking_at_current_slot");

//     if (cart.length === 0) {
//       cart = this.getCartFromStorage();
//     }

//     const now = moment();

//     cart.forEach((item: CartItem, index: number) => {

//       if (item != null) {

//         let booking_date = moment(`${item.date} ${item.time}`, "YYYY-MM-DD HH:mm");

//         let temp_booking_date = booking_date;

//         if(this.venue_accept_booking_at_current_slot == 'yes'){

//           if(item.next_slot != null){
            
//             temp_booking_date = moment(`${item.date} ${item.next_slot}`, "YYYY-MM-DD HH:mm");

//           }else{
            
//             temp_booking_date = temp_booking_date.add(item.time_taken, 'minutes')

//           }
//         }
       
//         if(temp_booking_date.isSameOrBefore(now)) {
//           this.removeItemExternal(index);
//           this.removeItem(index); // will update the cart across the app, with the exception of the current cart array
//           delete cart[index]; // will only affect the array we are currently looping over

//           this.notyf.open({
//             type: "info",
//             message: `Your appointment for a ${item.service_name} at ${item.venue_name} on ${booking_date.format(
//               "ddd Do MMM"
//             )} at ${booking_date.format("H:mm A")} has expired. It has been removed from your cart.`,
//             dismissible: true,
//             duration: 1000 * 10, // 10 seconds
//           });
//         }
        
//       }else{
//         this.removeItem(index);
//       }
//     });

//     if (cart.length > 0) {
//       this.resetTimeout();
//     }

//     return cart;
//   }

//   async getCartExternal() {
//     const response = await this.get("/cart").toPromise();

//     if (response["success"] === true) {

//       const cart = response["data"]["items"];
//       localStorage.setItem("accept_booking_at_current_slot", response["data"]["accept_booking_at_current_slot"]);
//       if (response["data"]["deleted_items"]) {
//         response["data"]["deleted_items"].forEach((item: CartItem) => {
//           const booking_date = moment(`${item.date} ${item.time}`, "YYYY-MM-DD HH:mm");

//           this.notyf.open({
//             type: "info",
//             message: `Your appointment for a ${item.service_name} at ${item.venue_name} on ${booking_date.format(
//               "ddd Do MMM"
//             )} at ${booking_date.format("H:mm A")} has expired. It has been removed from your cart.`,
//             dismissible: true,
//             duration: 1000 * 10, // 10 seconds
//           });
//         });
//       }
//       this._cart = cart;
//       this.cartSubject?.next(this._cart);
//       localStorage.setItem("cart", JSON.stringify(this._cart));

//       return cart;
//     }

//     return false;
//   }

//   saveCartExternal() {
//     this.post("/cart/save-cart", { items: this.getCart() }).subscribe(this.handleExternalResponse);
//   }

//   overwriteCart(cart: CartItem[]) {
//     if (this._cart.length > 0 && this._oldCartSet === false) {
//       this._oldCart = this._cart;
//       this._oldCartSet = true;
//     }

//     if (!(this._overwriteCart == cart)) {
//       this._cart = cart;
//       this._overwriteCart = cart;

//       this.cartSubject?.next(this._cart);
//     }

//     this.resetTimeout();
//   }

//   removeItem(index: number) {
//     const cart = this.cartSubject?.getValue();

//     if (cart[index]) {
//       cart.splice(index, 1);
//     }

//     localStorage.setItem("cart", JSON.stringify(cart));
//     this.cartSubject?.next(cart);

//     this.resetTimeout();

//     return cart;
//   }

//   removeItemExternal(index: number) {
//     this.post("/cart/remove-item", { index }).subscribe(this.handleExternalResponse);
//   }

//   clearCart() {
//     this._cart = [];

//     localStorage.setItem("cart", JSON.stringify(this._cart));
//     localStorage.removeItem("paymentIntentId");

//     this.cartSubject?.next(this._cart);

//     this.resetTimeout();

//     return this._cart;
//   }

//   clearCartExternal() {
//     return this.post("/cart/clear-cart").toPromise();
//   }

//   checkIfOnCheckoutPage() {
//     return this.router.url === "/checkout";
//   }

//   post(endpoint, params = {}) {  

//     return this.http.post(this.getUrl() +(endpoint), params, {
//       headers: this.headers,
//     });
//   }
  
//   get(endpoint, params = {}, external = false) {
//     if (!external) {
//       endpoint = this.getUrl() +(endpoint);
//     }
    
//       return this.http.get(endpoint, {
//         headers: this.headers,
//         params,
//       });
//   }
// }
