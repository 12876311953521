<ion-app>
  <div id="app-full-wrapper">
    <!-- <ion-header>
      <app-navbar></app-navbar>
    </ion-header> -->

    <ion-router-outlet [class.router-outlet-ios]="currentPlatform === 'ios'"></ion-router-outlet>
    <!-- <ion-footer>
      <app-footer></app-footer>
    </ion-footer> -->
  </div>
</ion-app>