<a [routerLink]="'/profile/appointments/' + booking.id" class="card rounded-0 text-dark">
  <div class="card-body p-2">
    <strong>Activity: {{ booking.service.name }}</strong>
    <br>
    <strong>Venue: {{ booking.venue.name }}</strong>
    <br>
    <span hidden> {{ booking.booking_date }} </span>
    <span> {{booking.start_date +' '+ booking.start_time}} (UK)</span>
    <br>
    <span [class]="booking.booking_class">{{ booking.status }}</span> 
  </div>
</a>