
<div class="mt-md-4">
  <div class="row">
    <div class="col">
          <ng-container *ngFor="let category of categories">
            <ng-container *ngIf="category.services.length > 0">
             
              <div class="col-12">
                <h5 class="category-name">{{ category.name }}</h5>
                <app-services-list [venue]="venue" class="w-100" [category]="category" [categoryName]="category?.name" [services]="category?.services"></app-services-list>
              </div>

              
              <tr style="height: 2rem;"></tr>
            </ng-container>
          </ng-container>
    </div>
  </div>
</div>