import { Component, OnInit, Input,AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { Booking } from 'src/app/interfaces/booking';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { NotyfService } from './../../services/notyf/notyf.service';
import { LoadingController, NavController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';

const REFRESH_APPOINTMENTS_TIMEOUT = 5 * 60 * 1000; // 5 minutes

@Component({
  selector: 'app-cancelled-bookings',
  templateUrl: './cancelled-bookings.component.html',
  styleUrls: ['./cancelled-bookings.component.scss'],
})
export class CancelledBookingsComponent implements OnInit,AfterViewInit, OnChanges {

  @Input() venue_id: number = null;
  @Input() current_venue: any = null;

  show_available_appointments:boolean = false;
  available_appointments: Booking[] = [];

  available_appointments_count = 0;

  available_appointment_index = 0;

  private appointments_sub;
  private appointments_timeout; 

  constructor(
    private api: ApiService,
    private env: EnvironmentService,
    private nav: NavController,
  ) { }

  ngAfterViewInit(){
    
  }

  ngOnChanges(changes: SimpleChanges){
    this.refreshAvailableAppointments()
  }
  

  ngOnInit() {

    if(this.current_venue){
      
      this.venue_id = this.current_venue?.id;

    }else{

      this.current_venue = JSON.parse(localStorage.getItem('current-venue'));

      this.venue_id = this.current_venue?.id;
      
    }

    this.refreshAvailableAppointments();
  }

  toggleAvailableAppointmentsModal(){

    if(this.show_available_appointments){
      this.show_available_appointments = false;
    }else{
      this.show_available_appointments = true;
    }
  }

  openCancelledApointment(id){

    this.nav.navigateForward('/available-appointments/' + id);

  }

  refreshAvailableAppointments() {

    // if (this.env.api.venue_id === null && this.venue_id === null) {
    //   return;
    // }


    if(this.current_venue){
      
      this.venue_id = this.current_venue?.id;

    }else{

      this.current_venue = JSON.parse(localStorage.getItem('current-venue'));

      this.venue_id = this.current_venue?.id;
      
    }

    if(this.venue_id == null){

      return;
    }

    

    let data = {};

    if (this.venue_id) {
      data['venue_id'] = this.venue_id;
    }

    if (this.appointments_sub) {
      this.appointments_sub.unsubscribe();
    }

    this.appointments_sub = this.api.get('/available-appointments', data).subscribe(
      response => {
        if (response['success']) {
          if (response['data'] != this.available_appointments) {
            this.available_appointments = response['data'];
            this.available_appointments_count = this.available_appointments.length;
          }

          this.appointments_sub.unsubscribe();
        }
      }
    );

    clearTimeout(this.appointments_timeout);

    this.appointments_timeout = setTimeout(() => this.refreshAvailableAppointments(), REFRESH_APPOINTMENTS_TIMEOUT);
  }


}
