<div class="container-xl mt-3 mt-md-5">
  <div class="row mb-4 d-flex">
    <div class="col" >
      <h3 *ngIf="!onHomePage">{{ page_title }} {{ location_string }}</h3>
      <h3 *ngIf="onHomePage" >Our venues</h3>
    </div>
  </div>
  <div class="row mb-5 mb-5">
    <ng-container *ngFor="let venue of venues" >
      <ng-container *ngIf="show_services === false || (show_services === true && (venue?.categories?.length > 0 || venue?.servies?.length > 0))">
        <div class="col-12 mb-4">
          <div class="row align-items-center venue_container">
            
            <div [class.col-6]="show_services === false" [class.col-12]="show_services === true" class="col-md-3 align-self-start"  style="padding: 0;">
              <div 
                class="venue-logo-container" 
                [ngStyle]="{'background-image': 'url(' +venue.banner_url + ')',
                'background-size': 'cover',
                'background-repeat': 'no-repeat',
                'background-position': 'center center'}"
                >
                <img [src]="venue.logo_url" [alt]="venue.name">
              </div>
            </div>
            <div [class.col-6]="show_services === false" [class.col-12]="show_services === true" class="col-md-9">
              <div class="row">
                <div class="col-12">
                  <table class="table venue-details">
                    <tr>
                      <th class="border-top-0 d-flex align-items-center justify-content-between">
                        {{ venue.name }} 
                        <a [routerLink]="'/venue/' + venue.slug" class="btn btn-outline-primary btn-sm float-right book-button">
                          View
                        </a>
                    </th>
                    </tr>
                    <tr>
                      <td>{{ venue.address | replace:'\\n':', ':true }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="venues.length === 0">
      <div class="col-12 mb-4">
        <h5 class="montserrat-regular">{{ no_results }}</h5>
      </div>
    </ng-container>
  </div>
</div>