<div class="card mt-3">
  <div class="card-body p-0 rounded-lg d-flex align-items-center">
    <a [routerLink]="'/venue/' + item.slug">
      <div class="venue-logo" [style.backgroundImage]="'url(' + item.logo + ')'"></div>
    </a>
    <!-- <a class="w-100" [routerLink]="'venues/' + item.slug"> -->
    <a class="w-100" routerLink="/venue">
      <div class="venue-description-container w-auto mx-3">
          <p class="venue-name mb-0">John Doe's Hair Venue</p>
          <p class="venue-description mb-0"><small>Hair-cuts, colouring and styling.</small></p>
          <div class="venue-meta mb-0 d-flex justify-content-between">
            <small class="venue-starting-price">Starting at <span style="font-weight: 600;">£<span class="venue-starting-price--price">10</span></span></small>
            <small class="venue-rating mb-0"><i class="fas fa-star"></i> 4.9</small>
        </div>
      </div>
    </a>
  </div>
</div>