import { Venue } from 'src/app/interfaces/venue';
import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { AccordionConfig } from 'ngx-bootstrap/accordion';
import { Category } from 'src/app/interfaces/category';
import { Service } from 'src/app/interfaces/service';
import { ApiService } from 'src/app/services/api/api.service';

export function getAccordionConfig(): AccordionConfig {
  return Object.assign(new AccordionConfig(), { closeOthers: true });
}

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
  providers: [{provide: AccordionConfig, useFactory: getAccordionConfig}]
})
export class CategoryListComponent implements OnInit, OnChanges {

  activeServices: Service[] = [];
  servicesByCategory: Category[] = [];

  selectedService: any; 

  @Input() categories: Category[];
  @Input() venue: Venue;
  @Input() venues: Venue[];
  @Input() venue_slug: string;

  mobileSelect: number = 0;

  constructor(
    private api: ApiService,
  ) {}

  ngOnInit() {
    if (this.categories) {
      this.prepareData(this.categories);
    } else if (this.venue) {
      this.categories = this.venue.categories;
      this.prepareData(this.categories);
    }
  }

  ngOnChanges() {
    if (this.categories) {
      this.prepareData(this.categories);
    } else if (this.venue) {
      this.categories = this.venue.categories;
      this.prepareData(this.categories);
    }
  }

  prepareData(categories: Category[]) {
    this.servicesByCategory = categories;
    this.activeServices = this.servicesByCategory[0]?.services || [];
  }

  changeServices(category: {services: []}, element: HTMLElement, index: number) {
    this.activeServices = category.services;
    this.mobileSelect = index;

    element.closest('.nav.nav-pills').querySelector('.active').classList.remove('active');
    element.classList.add('active');
  }

  changeServicesMobile() {
    // type-cast the response of querySelector to HTMLElement - otherwise angular/node throws an error on compile 
    const el = <HTMLElement>document.querySelector('a[data-index="' + this.mobileSelect + '"]');

    el.click();
  }

  segmentChanged(event: any) {
    this.activeServices = this.servicesByCategory[event.detail.value].services;
  }

  // setActiveVenue(venue: Venue, event: MouseEvent) {
  //   const currentVenue = JSON.parse(localStorage.getItem('current-venue'));

  //   if (currentVenue === null) {
  //     localStorage.setItem('current-venue', JSON.stringify(venue));
  //   }

  //   localStorage.setItem('next-venue', JSON.stringify(venue));
  // }
}
