<div class="jumbotron jumbotron-fluid d-md-flex flex-column justify-content-center align-items-center mb-0" id="hero-jumbotron">
  <span #slotContent class="w-100">
    <ng-content></ng-content>
  </span>
  <img *ngIf="!slotContent.innerHTML.trim()" [src]="image_link" alt="Category name" />
  <div class="jumbotron-container container-xl d-flex flex-column align-items-center">
    <h4 [hidden]="title_is_home_title" id="jumbotron-title" class="text-center mb-3 playball-regular text-white"></h4>
    <img [hidden]="!title_is_home_title" [src]="app_logo" class="hero-logo" alt="Community Sports Arenas">
    <!-- <h4 *ngIf="checkOnHomePage()" id="tagline" class="text-center mb-3 playball-regular text-white">{{ tagline }}</h4>
    <form class="justify-content-center px-5 py-4 mt-1 bg-light w-75 rounded-lg shadow" action="#" *ngIf="on_mainApp === true || (currentRoute.indexOf('/search') > -1)">
      <div class="row">
        <div class="col-12 mb-4 px-0">
          <div class="left-icon w-100">
            <span class="fas fa-search"></span>
            <input class="form-control" name="service" type="text" [(ngModel)]="search_query" (keydown.enter)="submitForm()" placeholder="Service you are searching for..." />
          </div>
        </div>
        
        <div class="col-12 px-0">
          <button #submitBtn [class.disabled]="search_query == '' && search_location == ''" [routerLink]="submit_link" [replaceUrl]="true" type="button" class="btn btn-block btn-dark lemon-milk-light">
            <i class="fas fa-search"></i> Search
          </button>
        </div>
      </div>
    </form> -->
  </div>
</div>
<app-hero-nav [on_mainApp]='on_mainApp' [show_active]="nav_show_active"></app-hero-nav>