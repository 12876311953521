<div class="container-fluid px-0 bg-dark d-md-block" id="hero_nav" *ngIf="on_mainApp === true">
  <div class="row">
    <div class="col-12">
      <!-- <ul class="d-flex list-group flex-row w-100 justify-content-around lemon-milk-light">
        <ng-container *ngFor="let category of categories">
          <li routerLinkActive="active" class="list-group-item border-0 bg-dark d-flex align-items-center">
            <a class="text-light" (click)="setActiveCategory(category)" [routerLink]="link !== null ? link : '/categories/' + category.slug">
              {{ category.name }}
            </a>
          </li>
        </ng-container>
      </ul> -->
      <div class="tagline-section text-light">
        {{tagline}}
      </div>
    </div>
  </div>
</div>

<div class="container-fluid px-0 bg-dark d-md-block" id="hero_nav" *ngIf="on_mainApp === false">
  <div class="row">
    <div class="col-12">
      <ul class="d-flex list-group flex-row w-100 justify-content-around lemon-milk-light">
        <ng-container>
          <!-- <li *ngIf="category.venues.length > 0 || category.slug === active_category?.slug" [class.active]="show_active && category.slug === active_category?.slug" class="list-group-item border-0 bg-dark"> -->
     
          <li routerLinkActive="active" class="list-group-item border-0 bg-dark d-flex align-items-center">
            <a class="nav-link" routerLink="/gallery" routerLinkActive="active">
              <!-- <i class="fas fa-image align-middle"></i> Gallery -->
              Gallery
            </a>
          </li>

          <li routerLinkActive="active" class="list-group-item border-0 bg-dark d-flex align-items-center">
            <a class="nav-link" routerLink="/categories" routerLinkActive="active">
              <!-- <i class="fas fa-image align-middle"></i> Gallery -->
              Categories
            </a>
          </li>

          <li routerLinkActive="active" class="list-group-item border-0 bg-dark d-flex align-items-center" *ngIf="!(currentRoute.indexOf('/search') > -1)">
            <a class="nav-link" routerLink="/search" routerLinkActive="active">
                <!-- <i class="fas fa-search align-middle"></i> Search -->
                Search
            </a>
          </li>
          
          <li routerLinkActive="active" class="list-group-item border-0 bg-dark d-flex align-items-center">
            <a class="nav-link" routerLink="/about-us" routerLinkActive="active">
              <!-- <i class="fas fa-info align-middle"></i> About -->
              About
            </a>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>