import { Component, Input} from '@angular/core';

@Component({
  selector: 'app-invoice-slot',
  templateUrl: './invoice-slot.component.html',
  styleUrls: ['./invoice-slot.component.scss'],
})
export class InvoiceSlotComponent {
  @Input() invoice;

  constructor() { }

}
