<div [hidden]="favourites.length === 0" class="container-fluid px-0 my-3 my-md-5">
  <div class="row">
    <div class="col">
      <h3 class="text-center mb-3">Your Favourites</h3>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <!-- <ngx-slick-carousel
        id="favouritesCarousel"
        #slickModal="slick-carousel"
        [config]="slickConfig"
        (init)="slickInit($event)"
        (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)"
      >
        <div ngxSlickItem *ngFor="let item of favourites">
          <div class="card shadow">
              <img class="card-img" [src]="item.img_url">
              <div class="black-overlay"></div>
              <div class="black-overlay"></div>
              <div class="black-overlay"></div>
              <div class="card-img-overlay d-flex flex-column">
                  <h5>{{ item.text }}</h5>
                  <a *ngIf="item.type === 'service'" [routerLink]="'/book-appointment/' + item.id" class="btn btn-primary mt-auto">
                    Book now
                  </a>
                  <a *ngIf="item.type === 'venue'" [routerLink]="'/venue/' + item.id" class="btn btn-primary mt-auto">
                    View
                  </a>
              </div>
          </div>
        </div>
      </ngx-slick-carousel> -->
    </div>
  </div>
</div>


