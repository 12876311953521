<div class="container-xl mt-4">
  <div class="row" *ngIf="!on_mainApp">
    <div class="col">
      <ng-container *ngFor="let service of services; let index = index;">
        <div class="row align-items-center border-bottom pb-4 service-item" [hidden]="service.display == 0" [class.mb-4]="index === 0" [class.mt-3]="index === 0" [class.my-4]="index !== 0">
          <div class="col-12 service-name">
            <span class="lemon-milk-light">{{ service.name }}</span>
          </div>
          
          <div class="col-12">
            <a #bookNowBtn [routerLink]="'/venue/'+venue.slug+'/book-appointment/' + service.id" class="btn btn-outline-primary btn-sm book-button">
              <span (click)="setActiveVenue(venue, $event)">Book Now</span>
            </a>
          </div>

          <div class="col-12">
            <div class="row w-100 mt-3 service-item-footer">
              <div class="col-3 d-flex justify-content-left align-items-center service-footer-item"><i class="fas fa-money-bill mr-1"></i><small>{{ service.price | currency}}</small></div>
              <div class="col-6 d-flex justify-content-center align-items-center service-footer-item"><i class="far fa-clock mr-1"></i> <small>{{ service.time_taken }}</small></div>
              <div (click)="openModal(service, bookNowBtn)" class="col-3 d-flex justify-content-end service-footer-item" *ngIf="service.description || service.description_full" style="cursor: pointer;"><i class="fa fa-info-circle mr-1"></i> <small>More</small></div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>