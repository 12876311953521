<header>
    <!--<div id="desktop-covid-warning" class="container-fluid covid-warning d-none d-md-block">
        <div class="row">
            <div class="col text-center">
                <p style="color:white" class="my-2 montserrat-regular">Powered by BeCreative</p>
            </div>
        </div>
    </div>-->
    <b [class.show]="offcanvasOpen" class="screen-overlay" (click)="closeOffcanvasNav()"></b>
    <nav id="main-nav" #mainNav [class.show]="offcanvasOpen" [class.ios-navbar]="currentPlatform === 'ios'" class="mobile-offcanvas d-block d-md-flex navbar navbar-expand-md navbar-light bg-primary align-items-end justify-content-around lemon-milk-light align-items-end">
        
        <a style="color:white;" class="navbar-brand text-center d-none d-md-block" routerLink="/">
            <!--<img [src]="app_logo" id="offcanvas-logo" class="w-100" alt="App Logo">-->
            {{venue_name}}
        </a>
        <div class="offcanvas-header py-2">
            <div class="row d-flex h-100 align-items-center">
                <div style="color:white; font-size: 20px;" class="col-8">
                    <img [src]="app_logo" id="offcanvas-logo" class="w-100" alt="App Logo">
                    <!-- {{venue_name}} -->
                </div>
                <div class="col-4 d-flex align-items-end justify-content-end closeOffcanvasNav">
                    <span class="mr-2" (click)="closeOffcanvasNav()">
                        <i style="color:white;" class="fas fa-times"></i>
                    </span>
                </div>
            </div>
        </div>
       
       <ul class="navbar-nav mr-auto align-items-end mt-md-0">
            <!-- <li class="nav-item d-none d-md-block dropdown my-2 my-md-0" *ngIf="on_mainApp === true">
                <span class="nav-link dropdown-toggle" (click)="toggleServicesDropdown()" appClickPreventDefault role="button">
                    Categories
                </span>
                <div [class.show]="show_services_dropdown" class="dropdown-menu" (clickOutside)="closeServicesDropdown()">
                    <ng-container *ngFor="let category of categories; let index = index;">
                        <a class="dropdown-item my-2 my-md-0" [class.my-2]="index !== (categories.length - 1)" [class.mt-3]="index === (categories.length - 1)" [class.mb-0]="index === (categories.length - 1)" (click)="closeServicesDropdown(category)" [routerLink]="'/categories/' + category.slug" routerLinkActive="active">{{ category.name }}</a>
                        <div *ngIf="index !== (categories.length - 1)" class="d-md-none" style="height: 1px;"></div>
                    </ng-container>
                </div>
            </li>

            <li class="nav-item d-none d-md-block dropdown my-2 my-md-0" *ngIf="on_mainApp === false">
                <a class="nav-link my-2 my-md-0" routerLink="/categories" routerLinkActive="active">Categories</a>
                
            </li>
             -->
        </ul>
        <div class="d-md-none" style="height: 1px;"></div>
        <a *ngIf="cart_empty === false" class="nav-link my-2 my-md-0 position-relative" routerLink="/cart" routerLinkActive="active">
            <i class="fas fa-shopping-cart"></i>
            <span class="badge badge-danger cart-count-badge" [class.d-none]="cartCount === 0" style="font-size: 10px;">{{ cartCount }}</span>
            <span [class.ml-3]="cartCount > 0"> Cart</span>
        </a>
        <div class="d-md-none" style="height: 1px;"></div>
        <ng-container *ngIf="loggedIn === false">
            <a class="nav-link my-2 my-md-0 d-none d-md-block" routerLink="/login" routerLinkActive="active">Sign In</a>
            <div class="d-md-none" style="height: 1px;"></div>
        </ng-container>
        <ng-container *ngIf="loggedIn === true">
            <a class="nav-link my-2 my-md-0 d-none d-md-block" routerLink="/profile" routerLinkActive="active">Account</a>
            <div class="d-md-none" style="height: 1px;"></div>
            <a class="nav-link my-2 my-md-0 d-none d-md-block" routerLink="" (click)="logout()">Logout</a>            
        </ng-container>

        <!-- <ng-container>
            <a *ngIf="cart_empty === true" id="book-now-button" class="nav-link my-2 my-md-0" href="/">Book Now</a>
        </ng-container> -->
        
        
        

        <ng-container class="">

            <a class="nav-link d-md-none my-2 my-md-0" routerLink="/" routerLinkActive="active">Home</a>

            <!-- <a *ngIf="!on_mainApp" class="nav-link d-md-none my-2 my-md-0" routerLink="/categories" routerLinkActive="active">Services</a> -->
            <ul *ngIf="on_mainApp" class="navbar-nav d-md-none mr-auto align-items-end mt-md-0" [hidden]="">
                <li class="nav-item d-md-block dropdown my-2 my-md-0 w-100" *ngIf="on_mainApp === true">
                    <span class="nav-link dropdown-toggle" (click)="toggleServicesDropdown()" appClickPreventDefault role="button">
                        Services
                    </span>
                    <div [class.show]="show_services_dropdown" class="dropdown-menu position-absolute w-100" (clickOutside)="closeServicesDropdown()">
                        <ng-container *ngFor="let category of categories; let index = index;">
                            <a class="dropdown-item my-2 my-md-0" [class.my-2]="index !== (categories.length - 1)" [class.mt-3]="index === (categories.length - 1)" [class.mb-0]="index === (categories.length - 1)" (click)="closeServicesDropdown(category)" [routerLink]="'/categories/' + category.slug" routerLinkActive="active">{{ category.name }}</a>
                            <div *ngIf="index !== (categories.length - 1)" class="d-md-none" style="height: 1px;"></div>
                        </ng-container>
                    </div>
                </li>
                
            </ul>

            <!-- <a class="nav-link d-md-none my-2 my-md-0" routerLink="/" routerLinkActive="active">Book Now</a> -->
            <a class="nav-link d-md-none my-2 my-md-0"  routerLink="/contact-us/booking_option" routerLinkActive="active" >Block Booking Enquires</a>

            <div class="d-md-none" style="height: 1px; background-color: aliceblue;"></div>

            <a class="nav-link d-md-none my-2 my-md-0" *ngIf="loggedIn === true" routerLink="/profile" routerLinkActive="active">Account</a>

            <a class="nav-link d-md-none my-2 my-md-0" *ngIf="loggedIn === false" routerLink="/login" routerLinkActive="active">Sign In</a>

            <a class="nav-link d-md-none my-2 my-md-0" *ngIf="loggedIn === true" routerLink="" (click)="logout()">Sign Out</a>

            <div class="d-md-none" style="height: 1px; background-color: aliceblue;"></div>

            <a class="nav-link d-md-none my-2 my-md-0" *ngIf="has_gallery_page === true" routerLink="/legal-collection/gallery" routerLinkActive="active">Gallery</a>
            
            <a class="nav-link d-md-none my-2 my-md-0" *ngIf="has_about_us_page === true " routerLink="/legal-collection/about-us" routerLinkActive="active">About Us</a>

            <a class="nav-link d-md-none my-2 my-md-0" *ngIf="has_terms_and_conditions_page === true" routerLink="/legal-collection/terms_and_conditions" routerLinkActive="active">Terms & Conditions</a>

            <a class="nav-link d-md-none my-2 my-md-0" *ngIf="has_privacy_policy_page === true" routerLink="/legal-collection/privacy-policy" routerLinkActive="active">Privacy Policy</a>

            
            

        </ng-container>


        <div #offcanvasFooter class="offcanvas-footer align-self-end justify-content-between d-flex d-md-none montserrat-regular" [class.w-100]="positionAbsolute" [class.position-absolute]="positionAbsolute">
            <span style="color:white;">&copy; Community Sports Arenas {{ copyright_year }}</span>
            <span *ngIf="mobile_app" style="color:white;"><small>v{{ app_version }}</small></span>
        </div>
    </nav>
    <div id="mobile-header" class="d-flex align-items-end d-md-none border-bottom">
        <div class="container-fluid">
            <div class="row mb-1">
                <div class="col-3 d-flex align-items-end flex-grow-1">
                    <!-- <span [class.d-none]="currentRoute === '/'" class="ml-2 mb-2" id="mobile-back" (click)="goBack()">
                        <i class="fas fa-chevron-left align-middle"></i>
                    </span> -->
                    <!-- <a [class.invisible]="currentRoute.indexOf('/search') > -1" [class.ml-2]="currentRoute === '/'" [class.ml-4]="currentRoute !== '/'" class="mb-2" id="mobile-search" routerLink="/search" routerLinkActive="active">
                        <i class="fas fa-search align-middle"></i>
                    </a> -->
                    <div class="mb-2 ml-2">
                        <span (click)="openOffcanvasNav()" style="color: white">
                            <i class="fas fa-bars"></i>
                        </span>
                    </div>
                </div>
                <div class="col-6 d-flex justify-content-center">
                    <a class="w-100 text-center" href="#">
                        <img [src]="app_logo" class="align-top" alt="App Logo" style="max-height: 67px;">
                    </a>
                </div>
                <div id="mobile-header-right-icons" class="col-3 d-flex align-items-end justify-content-around flex-grow-1">
                    <a routerLink="/cart" routerLinkActive="active" class="mr-2 mb-2 position-relative" *ngIf="cart_empty === false">
                        <i class="fas fa-shopping-cart align-middle"></i>
                        <span class="badge badge-danger cart-count-badge" [class.d-none]="cartCount === 0" style="font-size: 10px; color: white">{{ cartCount }}</span>
                    </a>
                    <!-- <a [class.d-none]="has_about_us_page === false" routerLink="/about-us" routerLinkActive="active" class="mr-2 mb-2">
                        <i class="fas fa-info align-middle"></i>
                    </a> -->
                </div>
            </div>
        </div>
    </div>
</header>
<ion-footer>
    <div id="mobile-footer" class="d-md-none w-100 border-top">
        <div class="container-fluid h-100">
            <div class="row h-100">
                <!-- <div class="col d-flex align-items-center justify-content-center mdi-24px">
                    <span (click)="openOffcanvasNav()">
                        <i class="fas fa-bars"></i>
                    </span>
                </div> -->
                <div class="col d-flex align-items-center justify-content-center mdi-24px">
                    <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <i class="fas fa-home"></i>
                    </a>
                </div>
                <div class="col d-flex align-items-center justify-content-center mdi-24px">
                    <a routerLink="/profile/appointments" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <i class="fas fa-calendar"></i>
                    </a>
                </div>
                <div class="col d-flex align-items-center justify-content-center mdi-24px">
                    <a routerLink="/profile" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <i class="fas fa-user"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</ion-footer>