import { SettingsService } from 'src/app/services/settings/settings.service';
import { CategoriesService } from 'src/app/services/categories/categories.service';
import { Category } from 'src/app/interfaces/category';
import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  runtimePlatform: string;
  currentPlatform : string;

  appName: String = this.env.settings.app.app_brand_name;

  categories: Category[] = [];

  has_about_us_page: boolean = false;
  has_gallery_page: boolean = false;

  facebook_url: string = null;
  instagram_url: string = null;
  twitter_url: string = null;
  youtube_url: string = null;
  android_app_link: string = '#';
  ios_app_link: string = '#';
  on_mainApp: boolean = true;
  has_privacy_policy_page: boolean;
  has_terms_and_conditions_page: boolean;

  constructor(
    private platform: Platform,
    private categoriesService: CategoriesService,
    private settingsService: SettingsService,
    private env: EnvironmentService,
    private api: ApiService,
  ) { 

    this.on_mainApp = this.env.api.venue_id === null;
    this.init();
  }

  async init(){

    this.appName = this.env.settings.app.app_brand_name;
    
    this.runtimePlatform = this.platform.is('hybrid') || this.platform.is('mobile') ? 'mobile' : 'web';

    this.currentPlatform = await this.settingsService.getPlatform();

    this.categoriesService.categories$.subscribe(categories => {
      this.categories = categories;
    })


    if(this.on_mainApp){      

      const mainAppSettings = await this.api.post('/settings/main-app-settings').toPromise();

      if(mainAppSettings){

        let mainData = mainAppSettings['data'];
        let mainAppsocialMedia = mainData.social_media;
        let mainSettings = mainData.app;
        this.has_about_us_page = (mainSettings?.has_about_us_page === 'yes');
        this.has_gallery_page = (mainSettings?.has_gallery_page === 'yes');
        this.has_privacy_policy_page = (mainSettings?.has_privacy_policy_page === 'yes');
        this.has_terms_and_conditions_page = (mainSettings?.has_terms_and_conditions_page === 'yes');

        if (mainAppsocialMedia?.social_media?.venue_facebook_url) {
          this.facebook_url = mainAppsocialMedia?.social_media?.venue_facebook_url
        }

        if (mainAppsocialMedia?.social_media?.venue_instagram_url) {
          this.instagram_url = mainAppsocialMedia.social_media.venue_instagram_url
        }

        if (mainAppsocialMedia?.social_media?.venue_twitter_url) {
          this.twitter_url = mainAppsocialMedia?.social_media?.venue_twitter_url
        }

        if (mainAppsocialMedia?.social_media?.venue_youtube_url) {
          this.youtube_url = mainAppsocialMedia?.social_media?.venue_youtube_url
        }

        if (mainAppsocialMedia?.app?.ios_app_link) {
          this.ios_app_link = mainAppsocialMedia?.app?.ios_app_link
        }

        if (mainAppsocialMedia?.app?.android_app_link) {
          this.android_app_link = mainAppsocialMedia?.app?.android_app_link
        }
      }

    }else{

      this.settingsService.settings$.subscribe(response => {

        this.has_about_us_page = (response.app?.has_about_us_page === 'yes');
        this.has_gallery_page = (response.app?.has_gallery_page === 'yes');
        this.has_privacy_policy_page = (response.app?.has_privacy_policy_page === 'yes');
        this.has_terms_and_conditions_page = (response.app?.has_terms_and_conditions_page === 'yes');

        if (response?.social_media?.venue_facebook_url) {
          this.facebook_url = response?.social_media?.venue_facebook_url
        }

        if (response?.social_media?.venue_instagram_url) {
          this.instagram_url = response.social_media.venue_instagram_url
        }

        if (response?.social_media?.venue_twitter_url) {
          this.twitter_url = response?.social_media?.venue_twitter_url
        }

        if (response?.social_media?.venue_youtube_url) {
          this.youtube_url = response?.social_media?.venue_youtube_url
        }

        if (response?.app?.ios_app_link) {
          this.ios_app_link = response?.app?.ios_app_link
        }

        if (response?.app?.android_app_link) {
          this.android_app_link = response?.app?.android_app_link
        }

      });
    }
  }


  ngOnInit() {
    this.init();
  }
  
  ngViewDidEnter(){
    this.init();    
  }

  setActiveCategory(category: Category) {
    this.categoriesService.setActiveCategory(category);
  }
}
