import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  
  public production = true;

  public api = {
    url: '',
    test_url: '',
    client_id: 0,
    client_secret: '',
    version: 'v1',
    venue_id: null,
  };

  public firebase = {
    apiKey: '',
    authDomain: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  }

  public test = '';
  
  public settings = {
    venue: {
      venue_logo: '',
      venue_banner: '',
      venue_name: ''
    },
    deposit: {},
    availability: {},
    app: {
      app_brand_name: '',
      home_page_title: '',
      home_page_subtitle: '',
      has_about_us_page: '',
      has_gallery_page: '',
      colour: '',
      default_image: '',
      seo_default_title: '',
      seo_default_description: '',
      seo_default_keywords: '',
    },
    pages: {}
  };
}
