import { Category } from "./../../interfaces/category";
import { CategoriesService } from "src/app/services/categories/categories.service";
import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { EnvironmentService } from "src/app/services/environment/environment.service";

@Component({
  selector: "app-hero-nav",
  templateUrl: "./hero-nav.component.html",
  styleUrls: ["./hero-nav.component.scss"],
})

export class HeroNavComponent implements OnInit {
  // @Input() active_category: Category = null;
  @Input() link: string = null;
  @Input() show_active = true;
  @Input() on_mainApp: boolean ;

  active_category: Category = null;

  categories: Category[] = [];

  active_slug: string = "";

  tagline: string = "Supporting local people through local venues";
  
  currentRoute: string = "";
  firstRoute = true;

  constructor(
    private categoriesService: CategoriesService,
    private router: Router,
    private environment: EnvironmentService,
  ) {}

  ngOnInit() {

    this.tagline = this.environment.settings.app.home_page_subtitle;

    this.router.events
    // Here we are passing all events through the pipe. The filter will only return NavigationEnd events
    .pipe(filter((e) => e instanceof NavigationEnd))
    // Get the current route from the event
    .subscribe((event: NavigationEnd) => {
      if (this.currentRoute !== undefined) {
        if (this.firstRoute && document.referrer.indexOf(window.location.hostname) === -1) {
          this.firstRoute = false;
        }
      }

      this.currentRoute = event.url;

      // console.log("this is the current route ========> ",this.currentRoute)

    });

    this.categoriesService.categories$.subscribe((categories) => {
      this.categories = categories;
    });

    if (this.active_category === null) {
      this.active_category = this.categoriesService.getActiveCategory();
    }

    this.active_slug = this.active_category?.slug || "";
  }

  setActiveCategory(category: Category) {
    this.categoriesService.setActiveCategory(category);
  }
}
