import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-nav-back',
  templateUrl: './nav-back.component.html',
  styleUrls: ['./nav-back.component.scss'],
})
export class NavBackComponent implements OnInit {

  @Input() go_back_string: string = null;
  @Input() button : any = null;

  constructor(
    private nav: NavController
  ) { }

  ngOnInit() {}

  goBack(){
    this.nav.back();
  }

}
