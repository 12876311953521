<div #hzDatePicker class="hz-datepicker-container"  *ngIf="viewLoaded">
        <h5 class="text-center selected-date mt-3">
            {{currentMonth}}            
        </h5>
    <div class="hz-datepicker-inner">
        <div class="days">
            <div class="days-prev" >
                <button [disabled]="!(currentDateInTime > todayInTime)" class="dp-button dp-button-prev {{dpConfig.navBtnClasses}}" (click)="onMonthNavClick(MONTH_NAVS.PREV)">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M249.38 336L170 256l79.38-80M181.03 256H342"/><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/></svg> -->
                    <ion-icon name="arrow-back-circle-outline"></ion-icon>
                </button>
            </div>
            <div class="days-inner">
                <div *ngFor="let btn of daysOfMonth" class="day-item" [style.display]="todayInTime <= btn.dateInTime ? '' : 'none'">
                    <!-- <div [style.display]="dpConfig.showDays? 'block' : 'none'" class="weekday" [ngClass]="{'is-weekend': btn.isWeekend}">
                        {{(btn.date | date: dpConfig.dayFormat).charAt(0)}}
                    </div> -->
                    <button id="{{btn.date | dateFormat:'YYYYMMDD'}}"
                        class="dp-button dp-button-day {{dpConfig.btnClasses}} {{btn.isSelected? dpConfig.selectedItemClass : ''}}"                      
                        (click)="onDateClick(btn)">
                    <div [style.display]="dpConfig.showDays? 'block' : 'none'" class="weekday" [ngClass]="{'is-weekend': btn.isWeekend}">
                        {{(btn.date | date: dpConfig.dayFormat).charAt(0)}}
                    </div>
                        <div class="daynumber">
                        {{btn.text}}
                        </div>
                    </button>
                </div>
              
            </div>
            <div class="days-next">
                <button class="dp-button dp-button-next {{dpConfig.navBtnClasses}}" (click)="onMonthNavClick(MONTH_NAVS.NEXT)">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M262.62 336L342 256l-79.38-80M330.97 256H170"/><path d="M256 448c106 0 192-86 192-192S362 64 256 64 64 150 64 256s86 192 192 192z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/></svg> -->
                    <ion-icon name="arrow-forward-circle-outline"></ion-icon>
                </button>
            </div>
        </div>
        <!-- <h4 class="text-center selected-date">
            {{selectedDate | date : dpConfig.selectedDateFormat}}            
        </h4> -->


    </div>
</div>