<div *ngIf="available_appointments_count > 0" class="container-xl mt-md-4">
  <div class="cancelled-booking-box text-center">
    <p>Some bookings have become available. Click the button below to view the bookings.</p>
    <button (click)="toggleAvailableAppointmentsModal()" class="btn btn-outline-primary">View Bookings</button>
  </div>
</div>
<div [hidden]="!show_available_appointments" class="related-service-modal">
  <div class="modal-body-container">
      <h3 class="modal-title">Available Slots</h3>
      <div class="related-service-container">
          <div *ngFor="let appointment of available_appointments; let index = index;" class="related-service" (click)="openCancelledApointment(appointment.id)">
              <h6 class="title">{{appointment.service_name}} on {{ appointment.booking_date | dateFormat:'YYYY-MM-DD':'ddd Do MMM YYYY' }} @ {{ appointment.booking_date | dateFormat:'hh:mm':'h:mm A' }} </h6>
              <span class="description"></span>
              <span class="price"></span>
          </div>
      </div>
      <div class="modal-footer-container">
          <button (click)="toggleAvailableAppointmentsModal()">No Thanks</button>
      </div>
  </div>
</div>