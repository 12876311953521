import { VoucherService } from "./../voucher/voucher.service";
import { CartService } from "./../cart/cart.service";
import { AlertController, NavController } from "@ionic/angular";
import { User } from "./../../interfaces/user";
import { TabsyncService } from "./../tabsync/tabsync.service";
import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import { EnvironmentService } from "../environment/environment.service";
import * as _moment from "moment";
import { shareReplay, tap } from "rxjs/operators";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { NotyfService } from "../notyf/notyf.service";

const moment = _moment;
const TIME_BETWEEN_LOGIN_CHECKS = 1000 * 60 * 5; // 5 mins
const TIME_BETWEEN_BANNED_CHECKS = 1000 * 60 * 5; // 5 mins

@Injectable({
  providedIn: "root",
})
export class AuthService {
  loggedInMessage = "logged in";
  loggedOutMessage = "logged out";

  baseUrl: string;
  options: any;

  loggedIn$: Observable<boolean>;

  private loggedInSubject: BehaviorSubject<boolean>;

  private lastBannedCheck: _moment.Moment = null;
  private lastLoggedInCheck: _moment.Moment = null;

  private checkedOnce = false;

  private isBanned = false;

  private isChecking = false;

  constructor(
    private api: ApiService,
    private environment: EnvironmentService,
    private tabsync: TabsyncService,
    private notyf: NotyfService,
    private alertController: AlertController,
    private navController: NavController,
    private cartService: CartService,
    private voucherService: VoucherService
  ) {
    this.loggedInSubject = new BehaviorSubject<boolean>(this.getAccessToken() !== null);
    this.loggedIn$ = this.loggedInSubject.asObservable();
    setTimeout(() => {
      // Check we are logged in - this service will only be instantiated once per request, so this function will only run once on page load
      // circular dependency error thrown when running this without setTimeout
      // the '0' means wait till the end of the current event loop to execute
      // (see https://developer.mozilla.org/en-US/docs/Web/JavaScript/EventLoop#zero_delays)
      this.isLoggedIn(true, true, true).then((isLoggedIn) => {
        this.loggedInSubject.next(isLoggedIn);
      });
    }, 0);
  }

  getAccessToken() {
    return localStorage.getItem("access_token");
  }

  getRefreshToken() {
    return localStorage.getItem("refresh_token");
  }

  getExpiration() {
    const expiration = localStorage.getItem("expires_in");
    const expires_at = JSON.parse(expiration);

    return moment(expires_at);
  }

  getTokenType() {
    return localStorage.getItem("token_type");
  }

  async isLoggedIn(displayAlert: boolean = true, checkBan: boolean = true, forceCheck = false) {

    /*while (this.isChecking) {
      // do nothing
    }*/

    this.isChecking = true;

    if (this.getAccessToken() !== null) {

      if (this.checkedOnce && this.lastLoggedInCheck !== null && forceCheck === false) {
        const isLoggedIn = this.loggedInSubject.getValue();

        if (
          isLoggedIn &&
          moment().isSameOrBefore(this.lastLoggedInCheck.add(TIME_BETWEEN_LOGIN_CHECKS, "milliseconds")) // using milliseconds as thats what JavaScript uses
        ) {

          if (checkBan) {
            
            const banResponse = await this.checkUserBan();

            if (banResponse === false) {
              this.isChecking = false;

              return false;
            }
          }

          this.isChecking = false;
          return true;
        }
      }

      let response;

      try {

        response = await this.api.post("/check-logged-in").toPromise();

        this.lastLoggedInCheck = moment();

        this.loggedInSubject.next(true);

        this.checkedOnce = true;
      } catch (err) {
        if (this.checkedOnce) {
          this.logout();

          if (displayAlert === true) {
            const alert = await this.alertController.create({
              header: "You have been logged out",
              message: "Click OK to go to the home page",
              buttons: [
                {
                  text: "Login",
                  handler: () => {
                    this.navController.navigateRoot("/login");
                  },
                },
                {
                  text: "OK",
                  handler: () => {
                    this.navController.navigateRoot("/");
                  },
                },
              ],
            });

            await alert.present();
          }

          this.lastLoggedInCheck = null;

          this.isChecking = false;

          return false;
        }
      }

      if (checkBan) {
        var banResponse = await this.checkUserBan(forceCheck);

        if (banResponse === false) {
          this.isChecking = false;
          return false;
        }
      }

      this.isChecking = false;
      return this.getAccessToken() && moment().isBefore(this.getExpiration());
    }

    this.isChecking = false;
    return false;
  }

  async checkUserBan(forceCheck = false) {
    if (this.lastBannedCheck !== null && forceCheck === false) {
      // using milliseconds as thats what JavaScript uses
      if (moment().isSameOrBefore(this.lastBannedCheck.add(TIME_BETWEEN_BANNED_CHECKS, "milliseconds"))) {
        this.lastBannedCheck = moment();
        // bypass the banned check if the user has been checked before this
        return true;
      }
    }

    let response;

    try {
      response = await this.api.post("/check-user-ban").toPromise();
      this.lastBannedCheck = moment();
    } catch (err) {
      this.logout();
      return false;
    }

    if (response.banned) {
      this.cartService.clearCart();
      this.voucherService.removeVoucher();
      this.logout();
      this.notyf.error("Your account has been suspened");
    }

    return true;
  }

  login(username: string, password: string) {
    return this.api
      .post("/oauth/token", {
        grant_type: "password",
        client_id: this.environment.api.client_id,
        client_secret: this.environment.api.client_secret,
        username: username,
        password: password,
        scope: "",
      })
      .pipe(
        tap(async (authResponse) => {
          this.setSession(authResponse);
        }),
        shareReplay()
      );
  }

  logout() {
    this.cartService
      .clearCartExternal()
      .then(async (response) => {
        await this.api.post("/revoke-token").toPromise();

        this.handleLogout();
      })
      .catch(() => {
        this.handleLogout();
      });

    this.tabsync.postMessage("logged out");

    this.loggedInSubject.next(false);
  }

  setSession(oauthResponse) {
    const expires_at = moment().add(oauthResponse.expires_in, "second");

    localStorage.setItem("access_token", oauthResponse.access_token);
    localStorage.setItem("refresh_token", oauthResponse.refresh_token);
    localStorage.setItem("token_type", oauthResponse.token_type);
    localStorage.setItem("expires_in", JSON.stringify(expires_at));

    this.tabsync.postMessage("logged in");

    this.loggedInSubject.next(true);

    this.lastLoggedInCheck = moment();
    this.lastBannedCheck = moment();
  }

  getUser(): User {
    return JSON.parse(localStorage.getItem("user"));
  }

  private async handleLogout() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("token_type");
    localStorage.removeItem("expires_in");
    this.cartService.clearCart();
    this.voucherService.removeVoucher();
    this.lastLoggedInCheck = null;
    this.lastBannedCheck = null;
  }
}


// import { VoucherService } from "./../voucher/voucher.service";
// import { CartService } from "./../cart/cart.service";
// import { AlertController, NavController } from "@ionic/angular";
// import { User } from "./../../interfaces/user";
// import { TabsyncService } from "./../tabsync/tabsync.service";
// import { Injectable } from "@angular/core";
// import { ApiService } from "../api/api.service";
// import { EnvironmentService } from "../environment/environment.service";
// import * as _moment from "moment";
// import { shareReplay, tap } from "rxjs/operators";
// import { BehaviorSubject, Observable, Subject } from "rxjs";
// import { NotyfService } from "../notyf/notyf.service";
// import { HttpClient, HttpHeaders } from "@angular/common/http";


// const moment = _moment;
// const TIME_BETWEEN_LOGIN_CHECKS = 1000 * 60 * 5; // 5 mins
// const TIME_BETWEEN_BANNED_CHECKS = 1000 * 60 * 5; // 5 mins

// @Injectable({
//   providedIn: "root",
// })
// export class AuthService {
//   loggedInMessage = "logged in";
//   loggedOutMessage = "logged out";

//   baseUrl: string;
//   options: any;

//   loggedIn$: Observable<boolean>;

//   private loggedInSubject: BehaviorSubject<boolean>;

//   private lastBannedCheck: _moment.Moment = null;
//   private lastLoggedInCheck: _moment.Moment = null;

//   private checkedOnce = false;

//   private isBanned = false;

//   private isChecking = false;
//   headers = {};

//   constructor(
//     private api: ApiService,
//     private environment: EnvironmentService,
//     private tabsync: TabsyncService,
//     private notyf: NotyfService,
//     private alertController: AlertController,
//     private navController: NavController,
//     private cartService: CartService,
//     private voucherService: VoucherService,
//     private http: HttpClient,
//     private env: EnvironmentService,
//   ) {
    
//  this.headers = new HttpHeaders({});
//     this.loggedInSubject = new BehaviorSubject<boolean>(this.getAccessToken() !== null);
//     this.loggedIn$ = this.loggedInSubject.asObservable();
//     setTimeout(() => {
//       // Check we are logged in - this service will only be instantiated once per request, so this function will only run once on page load
//       // circular dependency error thrown when running this without setTimeout
//       // the '0' means wait till the end of the current event loop to execute
//       // (see https://developer.mozilla.org/en-US/docs/Web/JavaScript/EventLoop#zero_delays)
//       this.isLoggedIn(true, true, true).then((isLoggedIn) => {
//         this.loggedInSubject.next(isLoggedIn);
//       });
//     }, 0);
//   }

//   getAccessToken() {
//     return localStorage.getItem("access_token");
//   }

//   getRefreshToken() {
//     return localStorage.getItem("refresh_token");
//   }

//   getExpiration() {
//     const expiration = localStorage.getItem("expires_in");
//     const expires_at = JSON.parse(expiration);

//     return moment(expires_at);
//   }

//   getTokenType() {
//     return localStorage.getItem("token_type");
//   }

//   async isLoggedIn(displayAlert: boolean = true, checkBan: boolean = true, forceCheck = false) {

//     /*while (this.isChecking) {
//       // do nothing
//     }*/

//     this.isChecking = true;

//     if (this.getAccessToken() !== null) {

//       if (this.checkedOnce && this.lastLoggedInCheck !== null && forceCheck === false) {
//         const isLoggedIn = this.loggedInSubject.getValue();

//         if (
//           isLoggedIn &&
//           moment().isSameOrBefore(this.lastLoggedInCheck.add(TIME_BETWEEN_LOGIN_CHECKS, "milliseconds")) // using milliseconds as thats what JavaScript uses
//         ) {

//           if (checkBan) {
            
//             const banResponse = await this.checkUserBan();

//             if (banResponse === false) {
//               this.isChecking = false;

//               return false;
//             }
//           }

//           this.isChecking = false;
//           return true;
//         }
//       }

//       let response;

//       try {

//         response = await this.post("/check-logged-in").toPromise();

//         this.lastLoggedInCheck = moment();

//         this.loggedInSubject.next(true);

//         this.checkedOnce = true;
//       } catch (err) {
//         if (this.checkedOnce) {
//           this.logout();

//           if (displayAlert === true) {
//             const alert = await this.alertController.create({
//               header: "You have been logged out",
//               message: "Click OK to go to the home page",
//               buttons: [
//                 {
//                   text: "Login",
//                   handler: () => {
//                     this.navController.navigateRoot("/login");
//                   },
//                 },
//                 {
//                   text: "OK",
//                   handler: () => {
//                     this.navController.navigateRoot("/");
//                   },
//                 },
//               ],
//             });

//             await alert.present();
//           }

//           this.lastLoggedInCheck = null;

//           this.isChecking = false;

//           return false;
//         }
//       }

//       if (checkBan) {
//         var banResponse = await this.checkUserBan(forceCheck);

//         if (banResponse === false) {
//           this.isChecking = false;
//           return false;
//         }
//       }

//       this.isChecking = false;
//       return this.getAccessToken() && moment().isBefore(this.getExpiration());
//     }

//     this.isChecking = false;
//     return false;
//   }

//   async checkUserBan(forceCheck = false) {
//     if (this.lastBannedCheck !== null && forceCheck === false) {
//       // using milliseconds as thats what JavaScript uses
//       if (moment().isSameOrBefore(this.lastBannedCheck.add(TIME_BETWEEN_BANNED_CHECKS, "milliseconds"))) {
//         this.lastBannedCheck = moment();
//         // bypass the banned check if the user has been checked before this
//         return true;
//       }
//     }

//     let response;

//     try {
//       response = await this.post("/check-user-ban").toPromise();
//       this.lastBannedCheck = moment();
//     } catch (err) {
//       this.logout();
//       return false;
//     }

//     if (response.banned) {
//       this.cartService.clearCart();
//       this.voucherService.removeVoucher();
//       this.logout();
//       this.notyf.error("Your account has been suspened");
//     }

//     return true;
//   }

//   login(username: string, password: string) {
//     return this.post("/oauth/token", {
//         grant_type: "password",
//         client_id: this.environment.api.client_id,
//         client_secret: this.environment.api.client_secret,
//         username: username,
//         password: password,
//         scope: "",
//       })
//       .pipe(
//         tap(async (authResponse) => {
//           this.setSession(authResponse);
//         }),
//         shareReplay()
//       );
//   }

  

//   logout() {
//     this.cartService
//       .clearCartExternal()
//       .then(async (response) => {
//         await this.post("/revoke-token").toPromise();

//         this.handleLogout();
//       })
//       .catch(() => {
//         this.handleLogout();
//       });

//     this.tabsync.postMessage("logged out");

//     this.loggedInSubject.next(false);
//   }

//   setSession(oauthResponse) {
//     const expires_at = moment().add(oauthResponse.expires_in, "second");

//     localStorage.setItem("access_token", oauthResponse.access_token);
//     localStorage.setItem("refresh_token", oauthResponse.refresh_token);
//     localStorage.setItem("token_type", oauthResponse.token_type);
//     localStorage.setItem("expires_in", JSON.stringify(expires_at));

//     this.tabsync.postMessage("logged in");

//     this.loggedInSubject.next(true);

//     this.lastLoggedInCheck = moment();
//     this.lastBannedCheck = moment();
//   }

//   getUser(): User {
//     return JSON.parse(localStorage.getItem("user"));
//   }

//   private async handleLogout() {
//     localStorage.removeItem("access_token");
//     localStorage.removeItem("refresh_token");
//     localStorage.removeItem("token_type");
//     localStorage.removeItem("expires_in");
//     this.cartService.clearCart();
//     this.voucherService.removeVoucher();
//     this.lastLoggedInCheck = null;
//     this.lastBannedCheck = null;
//   }

 

//   post(endpoint, params = {}) {  

//     return this.http.post(this.getUrl() +(endpoint), params, {
//       headers: this.headers,
//     });
//   }

//   public getUrl() {

//     console.log("getUrl =========> ",localStorage.getItem("url") || this.env.api.url)

//     return localStorage.getItem("url") || this.env.api.url;

//   }
  
// }

