<div *ngIf="available_appointments_count > 0" class="container mt-4">
  <div class="row">
    <div class="col">
      <div class="alert alert-info" role="alert">
        <h4 class="alert-heading">Available Appointments</h4>
        <p>An appointment for a {{ available_appointments[available_appointment_index].service_name }} has become available at {{ available_appointments[available_appointment_index].venue_name }}.</p>
        <p>The appointment is on {{ available_appointments[available_appointment_index].booking_date | dateFormat:'YYYY-MM-DD HH:mm:ss':'ddd Do MMM YYYY [at] h:mma' }}</p>
        <div class="mt-2 d-flex justify-content-between">
          <button [routerLink]="'/available-appointments/' + available_appointments[available_appointment_index].id" type="button" class="btn btn-primary">Book now</button>
          <div class="d-flex">
            <div class="btn-group" [class.d-none]="available_appointments_count <= 1">
              <button type="button" class="btn btn-secondary" (click)="previousAvailableAppointment()">
                <i class="fas fa-chevron-left"></i>&nbsp;<span class="d-none d-lg-inline">Previous</span>
              </button>
              <button type="button" class="btn btn-secondary" (click)="nextAvailableAppointment()">
                <span class="d-none d-lg-inline">Next</span>&nbsp;<i class="fas fa-chevron-right"></i>
              </button>
            </div>
            <span [class.d-none]="available_appointments_count <= 1" [class.d-flex]="available_appointments_count > 1" class="align-self-end ml-1">{{ available_appointment_index + 1 }}/{{ available_appointments_count }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>